<p-dialog #dialog header="{{'Quản lý file' | translate}}" class="sv-modal" [(visible)]="isShow" [modal]="true"
    [responsive]="true" [styleClass]="'sv-modal force-full-width'" [contentStyle]="{'max-height': getMaxDialogHeight()}"
    [style]="{'width': '1300px'}" closeIcon="ci ci-close" maximizeIcon="ci ci-maximize" minimizeIcon="ci ci-minimize"
    [maximizable]="true" [autoZIndex]="true">
    <p-tabView class="tn-modal-tab">
        <p-tabPanel header="{{'Kho dữ liệu cá nhân' | translate}}">
            <app-fssytem-file-explorer-modal #pModal (closePopup)="onPopupClosed($event)">
            </app-fssytem-file-explorer-modal>
        </p-tabPanel>
        <p-tabPanel header="{{'Kho dữ liệu tổ / bộ môn' | translate}}">
            <app-fssystem-file-explorer-bygroup-modal #pModalByGroup (closePopup)="onPopupClosed($event)">
            </app-fssystem-file-explorer-bygroup-modal>
        </p-tabPanel>
        <p-tabPanel header="{{'Chia sẻ với tôi' | translate}}">
            <app-fssystem-shared-with-me-modal #pModalShareWithMe (closePopup)="onPopupClosed($event)">
            </app-fssystem-shared-with-me-modal>
        </p-tabPanel>
    </p-tabView>
</p-dialog>