import { Component, AfterViewInit, Renderer2, ViewChild, OnDestroy, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { authConfig } from './config/oidc-auth.config';
import { UserService, AuthenticationService } from 'sv-shared';
import { DmNamHocService } from './svmlearning/services/dm-nam-hoc.service';
import { DmNamhoc } from './svmlearning/models/dmnamhoc';
import { Spinkit } from 'ng-http-loader';
import { JwksValidationHandler } from 'angular-oauth2-oidc-jwks';
import { OAuthService, OAuthInfoEvent } from 'angular-oauth2-oidc';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements AfterViewInit, OnDestroy, OnInit {

    currYear: DmNamhoc = new DmNamhoc();
    public spinkit = Spinkit;
    constructor(
        private translate: TranslateService,
        private _oauthService: OAuthService,
        private _userService: UserService,
        protected _dmNamHocService: DmNamHocService,
    ) {
        this.translate.addLangs(['vn', 'en']);
        if (localStorage.getItem('language') !== null) {
            this.translate.use(localStorage.getItem('language'));
        } else {
            localStorage.setItem('language', 'vn');
            this.translate.setDefaultLang('vn');
        }
        // console.log(environment);
    }

    ngAfterViewInit() {
        // setTimeout(() => { this.layoutMenuScrollerViewChild.moveBar(); }, 100);
    }

    async ngOnInit() {
        this.configureWithNewConfigApi();
        await this._dmNamHocService.GetSchoolYear().then(rs => {
            this.currYear = rs;
        })
    }

    private configureWithNewConfigApi() {
        this._oauthService.setStorage(localStorage);
        this._oauthService.configure(authConfig);
        this._oauthService.tokenValidationHandler = new JwksValidationHandler();
        if (this._oauthService.hasValidAccessToken()) {
            this._oauthService.tryLogin().then(rs => {
                this._oauthService.events.subscribe((event: OAuthInfoEvent) => {
                    if (event.type === 'token_expires') {
                        this._oauthService.silentRefresh();
                    }
                });
            });
            this._userService.returnPromises();
        }
    }



    ngOnDestroy(): void {
    }

    newGuid() {
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
            // tslint:disable-next-line:no-bitwise
            const r = Math.random() * 16 | 0, v = c === 'x' ? r : (r & 0x3 | 0x8);
            return v.toString(16);
        });
    }
}

