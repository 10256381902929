<p-dialog #dialog [header]="'Xem chi tiết file'" class="sv-modal" [(visible)]="isShow" [modal]="true"
    [responsive]="true" [styleClass]="'sv-modal force-full-width'" [style]="{'width': '1300px'}" closeIcon="ci ci-close"
    maximizeIcon="ci ci-maximize" minimizeIcon="ci ci-minimize" [maximizable]="true" [autoZIndex]="true">
    <div class="sv-modal-content-panel sv-form-container">
        <div *ngIf="isImage" class="text-center" style="max-height:530px;overflow:scroll;">
            <img src="{{fileUrl}}" style="width:100%;" />
        </div>
        <div id="fileViewContent" style="display:none;" *ngIf="!isImage">
        </div>
        <div *ngIf="!isImage" style="width: 100%; height: 100%; min-height: 800px;">
            <div id="file-viewer"></div>
        </div>
    </div>
    <p-footer>
    </p-footer>
</p-dialog>