import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DialogModule } from 'primeng/dialog';
import { ButtonModule } from 'primeng/button';
import { TranslateModule } from '@ngx-translate/core';
import { BreadcrumbModule } from 'primeng/breadcrumb';
import { TabViewModule } from 'primeng/tabview';
import { DataViewModule } from 'primeng/dataview';
import { ToastModule } from 'primeng/toast';
import { RadioButtonModule } from 'primeng/radiobutton';
import { CheckboxModule } from 'primeng/checkbox';
import { FileIconPipe } from './pipes/file-icon.pipe';
import { FileSizePipe } from './pipes/file-size.pipe';
import { MenubarModule } from 'primeng/menubar';
import { TreeModule } from 'primeng/tree';
import { ScrollPanelModule } from 'primeng/scrollpanel';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { BlockUIModule } from 'primeng/blockui';
import { MenuModule } from 'primeng/menu';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { SpinnerModule } from 'primeng/spinner';
import { EditorModule } from 'primeng/editor';
import { InputSwitchModule } from 'primeng/inputswitch';
import { DragDropModule } from 'primeng/dragdrop';
import { ChipsModule } from 'primeng/chips';
import { SliderModule } from 'primeng/slider';
import { CardModule } from 'primeng/card';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { InputMaskModule } from 'primeng/inputmask';
import { MultiSelectModule } from 'primeng/multiselect';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { ContextMenuModule } from 'primeng/contextmenu';
import { TableModule } from 'primeng/table';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { FileUploadModule } from 'primeng/fileupload';
import { AuthorizeDirective } from './directives/authorize.directive';
import { StringFormatPipe } from './pipes/string-format.pipe';
import { UserFormatPipe } from './pipes/user-format.pipe';
import { BooleanFormatPipe } from './pipes/boolean-format.pipe';
import { ApprovalPipe } from './pipes/approval.pipe';
import { ValidationSummaryComponent } from './components/validation-summary/validation-summary.component';
import { AutofocusDirective } from './directives/autofocus.directive';
import { SummaryPipe } from './pipes/summary.pipe';
import { AutoCompleteComponent } from './components/autocomplete/input.component';
import { AutocompleteDatasourceComponent } from './components/autocomplete/autocomplete-datasource/autocomplete-datasource.component';
import { ValidationInlineComponent } from './components/validation-inline/validation-inline.component';
import { TrangThaiPipe } from './pipes/trangThai.pipe';
import { HighlightSearchPipe } from './pipes/highlight.pipe';
import { SvBreadcrumbComponemt } from './components/breadcrumb/breadcrumb.componemt';
import { FileExplorerComponent } from './components/file-manager/file-explorer/file-explorer.component';
import { FolderFormComponent } from './components/file-manager/folder-form/folder-form.component';
import { FileUploadComponent } from './components/file-manager/file-upload/file-upload.component';
import { FileViewerComponent } from './components/file-manager/file-viewer/file-viewer.component';
import { SvPaginationComponent } from './components/paging/pagination.component';
import { FileExplorerModalComponent } from './components/file-manager/file-explorer-modal/file-explorer-modal.component';
import { FileNamePipe } from './pipes/file-name.pipe';
import { CourseItemIconPipe } from './pipes/course-item-icon.pipe';
import { SafePipe } from './pipes/safe.pipe';
import { FileExplorerShareWithMeComponent } from './components/file-manager/file-explorer-share-with-me/file-explorer-share-with-me.component';
import { FileExplorerTrashComponent } from './components/file-manager/file-explorer-trash/file-explorer-trash.component';
import { FileFolderShareComponent } from './components/file-manager/file-folder-share/file-folder-share.component';
import { FileFolderMoveComponent } from './components/file-manager/file-folder-move/file-folder-move.component';
import { FileExplorerShareLimitComponent } from './components/file-manager/file-explorer-share-limit/file-explorer-share-limit.component';
import { FileExplorerShareLimitModalComponent } from './components/file-manager/file-explorer-share-limit-modal/file-explorer-share-limit-modal.component';
import { FileManagerModalComponent } from './components/file-manager/file-manager-modal/file-manager-modal.component';
import { FileExplorerShareWithMeModalComponent } from './components/file-manager/file-explorer-share-with-me-modal/file-explorer-share-with-me-modal.component';
import { TimeAgoPipe } from './pipes/time-ago.pipe';

import { FssytemFileExplorerComponent } from './components/fssytem/file-explorer/fssytem-file-explorer.component';
import { FssytemFolderFormComponent } from './components/fssytem/folder-form/fssytem-folder-form.component';
import { FssystemFileUploadComponent } from './components/fssytem/file-upload/fssystem-file-upload.component';
import { FssystemFileViewerComponent } from './components/fssytem/file-viewer/fssystem-file-viewer.component';
import { FssytemFileTrashComponent } from './components/fssytem/file-trash/fssytem-file-trash.component';
import { FssystemItemSharedComponent } from './components/fssytem/item-shared/fssystem-item-shared.component';
import { FssystemSharedWithMeComponent } from './components/fssytem/shared-with-me/fssystem-shared-with-me.component';
import { FssytemFolderUploadComponent } from './components/fssytem/folder-upload/fssytem-folder-upload.component';
import { FssytemFileExplorerModalComponent } from './components/fssytem/modal-file-explorer/fssytem-file-explorer-modal.component';
import { IconPipe } from './pipes/icon.pipe';
import { FssystemFileExplorerBygroupComponent } from './components/fssytem/file-explorer-bygroup/fssystem-file-explorer-bygroup.component';
import { FssystemFileExplorerUseridComponent } from './components/fssytem/file-explorer-userid/fssystem-file-explorer-userid.component';
import { FssystemFileExplorerBygroupModalComponent } from './components/fssytem/modal-file-explorer-bygroup/fssystem-file-explorer-bygroup-modal.component';
import { FssytemFileExplorerMyModalComponent } from './components/fssytem/modal-file-explorer-my/fssytem-file-explorer-my-modal.component';
import { FssystemModalComponent } from './components/fssytem/modal-fssystem/fssystem-modal.component';
import { FssystemSharedWithMeModalComponent } from './components/fssytem/modal-shared-with-me/fssystem-shared-with-me-modal.component';
import { DropdownModule } from 'primeng/dropdown';
import { TooltipModule } from 'primeng/tooltip';
import { CalendarModule } from 'primeng/calendar';
import { InputTextModule } from 'primeng/inputtext';
import { PaginatorModule } from 'primeng/paginator';
import { TriStateCheckboxModule } from 'primeng/tristatecheckbox';
import { ThumborPipe } from './pipes/thumbor.pipe';

export function coreDeclaration() {
    return [
        CourseItemIconPipe,
        SafePipe,
        FileIconPipe,
        TimeAgoPipe,
        FileSizePipe,
        FileNamePipe,
        StringFormatPipe,
        UserFormatPipe,
        BooleanFormatPipe,
        AuthorizeDirective,
        ApprovalPipe,
        ThumborPipe,
        ValidationSummaryComponent,
        AutofocusDirective,
        SummaryPipe,
        AutoCompleteComponent,
        AutocompleteDatasourceComponent,
        ValidationInlineComponent,
        TrangThaiPipe,
        HighlightSearchPipe,
        SvBreadcrumbComponemt,
        FileExplorerComponent,
        FileUploadComponent,
        FolderFormComponent,
        FileViewerComponent,
        SvPaginationComponent,
        FileExplorerModalComponent,
        FileExplorerShareLimitComponent,
        FileExplorerShareWithMeComponent,
        FileExplorerTrashComponent,
        FileFolderMoveComponent,
        FileFolderShareComponent,
        FileExplorerShareLimitModalComponent,
        FileManagerModalComponent,
        FileExplorerShareWithMeModalComponent,

        IconPipe,
        FssytemFileExplorerComponent,
        FssytemFolderFormComponent,
        FssystemFileUploadComponent,
        FssystemFileViewerComponent,
        FssytemFileTrashComponent,
        FssystemItemSharedComponent,
        FssystemSharedWithMeComponent,
        FssytemFolderUploadComponent,
        FssytemFileExplorerModalComponent,
        FssystemFileExplorerBygroupComponent,
        FssystemFileExplorerUseridComponent,
        FssystemFileExplorerBygroupModalComponent,
        FssytemFileExplorerMyModalComponent,
        FssystemSharedWithMeModalComponent,
        FssystemModalComponent,
    ];
};

export function coreModuleImport() {
    return [
        CommonModule,
        DialogModule,
        ButtonModule,
        BreadcrumbModule,
        DropdownModule,
        MultiSelectModule,
        TabViewModule,
        DataViewModule,
        TooltipModule,
        ToastModule,
        MenubarModule,
        ContextMenuModule,
        TableModule,
        ConfirmDialogModule,
        FileUploadModule,
        FormsModule,
        RadioButtonModule,
        CalendarModule,
        CheckboxModule,
        InputTextModule,
        ReactiveFormsModule,
        TranslateModule,
        TreeModule,
        ScrollPanelModule,
        AutoCompleteModule,
        PaginatorModule,
        BlockUIModule,
        MenuModule,
        InputTextareaModule,
        SpinnerModule,
        EditorModule,
        InputSwitchModule,
        TableModule,
        ToastModule,
        DialogModule,
        ReactiveFormsModule,
        ChipsModule,
        SliderModule,
        CardModule,
        OverlayPanelModule,
        InputMaskModule,
        TriStateCheckboxModule,
        DragDropModule,
    ];
}

@NgModule({
    imports: coreModuleImport(),
    providers: [

    ],
    declarations: coreDeclaration(),
    exports: [...coreDeclaration(), ...coreModuleImport()]
})
export class SvComponentModule {
    static forRoot(): ModuleWithProviders<SvComponentModule> {
        return {
            ngModule: SvComponentModule
        };
    }
}
