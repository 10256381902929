import { FsItemSharedService } from './../../../services/fs-item-shared.service';
import { FsSystemFileService } from './../../../services/fssystem-file.service';
import { FsSystemFoldersService } from '../../../services/fssystem-folder.service';
import { ExTreeNode, FsItemModel } from './../../../models/fsitem';
import { Component, OnInit, Injector, ViewChild, Input, HostListener } from '@angular/core';
import { SecondPageIndexBase } from '../../../classes/base/second-page-index-base';
import { environment } from '../../../../../../../src/environments/environment';
import { FssystemFileViewerComponent } from '../file-viewer/fssystem-file-viewer.component';
import { OverlayPanel } from 'primeng/overlaypanel';

@Component({
    selector: 'app-fssystem-shared-with-me',
    templateUrl: './fssystem-shared-with-me.component.html',
    styleUrls: ['./fssystem-shared-with-me.component.scss']
})
export class FssystemSharedWithMeComponent extends SecondPageIndexBase implements OnInit {
    rootTreeNode: ExTreeNode[] = [
        {
            'label': 'Menu',
            'data': '-1',
            'expandedIcon': 'fa-folder-open',
            'collapsedIcon': 'fa-folder',
            'children': [],
            'type': '1',
        }];
    selectedTreeNode: ExTreeNode;
    selectedFsItem = new FsItemModel();
    @ViewChild('pContext') pContext: OverlayPanel;
    @ViewChild('pFileViewer') pFileViewer: FssystemFileViewerComponent;
    root = 0;
    constructor(
        protected _fsItemSharedService: FsItemSharedService,
        protected _fsSystemFoldersService: FsSystemFoldersService,
        protected _fsSystemFileService: FsSystemFileService,
        protected _injector: Injector,
    ) {
        super(null, _injector);
    }

    ngOnInit() {
        this.getData();
    }

    editFolder() {
        const item = this.selectedItems[0];
        // this.pEditFolder.showPopup(item);
    }

    addFolder() {
        var model = {
            ten: '',
            physicalPathParent: this.selectedFsItem.physicalPath
        };
        // this.pEditFolder.showPopup(model);
    }

    edit() {
        const item = this.selectedItems[0];
        if (item.idFsItemType == 1) {
            this.editFolder();
        }
    }

    async getData() {
        this.root = 0;
        this._fsItemSharedService.getsSharedWithMe().then(rs => {
            if (rs.success) {
                this.dataSource = rs.data;
                this.totalRecord = rs.data.length;
            }
        })
    }

    async getDataTable() {
        if (this.pContext !== undefined) {
            this.pContext.hide();
        }

        this.dataSource = [];
        // console.log(this.selectedFsItem);
        if (this.selectedFsItem.ten == '...') {
            this.root--;
        } else {
            this.root++;
        }
        if (this.root > 0) {
            this.dataSource.push({
                physicalPath: this.selectedFsItem?.physicalPathParent,
                idFsItemType: 1,
                ten: '...',
            });

            await this._fsSystemFoldersService.getsChildFolder(this.selectedFsItem).then(rsFo => {
                if (rsFo.success) {
                    rsFo.data.map(item =>
                        this.dataSource.push(item)
                    );
                }
            });

            if (this.selectedFsItem.physicalPath != '') {
                await this._fsSystemFileService.getsByFsFolder(this.selectedFsItem).then(rsFi => {
                    if (rsFi.success) {
                        rsFi.data.map(item =>
                            this.dataSource.push(item)
                        );
                    }
                })
            }
        }
        else {
            this.getData();
        }
    }

    onDoubleItemClick(item: any) {
        this.selectedFsItem = item;
        if (item.idFsItemType === 1) {
            this.getDataTable();
        } else {
            // Xem file online (nếu được hỗ trợ)
            if (this._fsSystemFileService.isSupportedViewOnline(this.selectedFsItem.ten)) {
                this.pFileViewer.showPopup(true, this.selectedFsItem);
            } else {
            }
        }
    }

    nodeSelect(event) {
        this.getDataTable();
    }

    checkNode(folderId) {
        this.selectedTreeNode = undefined;
        this.rootTreeNode.forEach(element => {
            this.treeFilter(element, folderId);
        });
    }

    treeFilter(node: ExTreeNode, folderId) {
        if (this.selectedTreeNode !== undefined && this.selectedTreeNode.data === folderId) {
            return;
        }
        if (node.data === folderId) {
            this.selectedTreeNode = node;
            this.nodeSelect(null);
            return;
        }
        if (node.children) {
            node.children.forEach(childNode => {
                this.treeFilter(childNode, folderId);
            });
        }
    }


    async viewFile() {
        this.pContext.hide();
        if (this.selectedFsItem.idFsItemType === 1) {
            // Mở thư mục con
            this.onDoubleItemClick(this.selectedFsItem);
        } else {
            // Xem file online (nếu được hỗ trợ)
            if (this._fsSystemFileService.isSupportedViewOnline(this.selectedFsItem.ten)) {

                this.pFileViewer.showPopup(true, this.selectedFsItem);
            } else {
                // download file
                top.location.href = await this._fsSystemFileService.getAnonymousDownloadUrl(this.selectedFsItem.webPath);
            }
        }
    }

    copyUrl() {
        this.pContext.hide();
        if (this.selectedFsItem.idFsItemType > 1) {
            document.addEventListener('copy', (e: ClipboardEvent) => {
                e.clipboardData.setData('text/plain', (`${environment.apiDomain.svFsSystemEndpoint}${this.selectedFsItem.webPath}`));
                e.preventDefault();
                document.removeEventListener('copy', null);
            });
            document.execCommand('copy');
            this._notifierService.showSuccess('Đã copy link vào clipboard');
        }

    }

    goHome() {
        this.getData();
    }

    uploadFiles() {
        // this.pUpload.showPopup(this.itemFolder);
    }

    async onClickFsItem(item: any) {
        await this.dataSource.forEach(element => {
            element.isClick = false;
        });
        item.isClick = true;
    }

    showContectMenu(event: any, item: any) {
        this.selectedFsItem = item;
        this.pContext.toggle(event);
    }

    @HostListener('contextmenu', ['$event'])
    onRightClick(event) {
        event.preventDefault();
    }

    deleteFsItem() {
        this.pContext.hide();
        this._notifierService.showDeleteConfirm().then(rs => {

        });
    }

}
