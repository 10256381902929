import { Component, OnInit, EventEmitter, Output } from '@angular/core';
import { DmCapHocService } from '../../svmlearning/services/dm-cap-hoc.service';
import { DmKhoiLopService } from '../../svmlearning/services/dm-khoi-lop.service';
import { DmMonHocService } from '../../svmlearning/services/dm-mon-hoc.service';
import { ActivatedRoute } from '@angular/router';

@Component({
    selector: 'app-courses-boxsearch',
    templateUrl: './courses-boxsearch.component.html',
    styleUrls: ['./courses-boxsearch.component.scss']
})
export class CoursesBoxsearchComponent implements OnInit {
    dsCapHoc = [];
    dsKhoiLop = [];
    dsMonHoc = [];
    idDmCapHoc = 0;
    idDmKhoiLop = 0;
    idDmMonHoc = 0;
    query = '';
    isChecked = false;
    @Output() goSearch = new EventEmitter<any>();

    constructor(
        protected _dmCapHocService: DmCapHocService,
        protected _dmKhoiLopService: DmKhoiLopService,
        protected _dmMonHocService: DmMonHocService,
        private _route: ActivatedRoute
    ) { }

    async ngOnInit() {
        await this.getDmCapHoc();
        this._route.queryParams.subscribe(params => {
            if (params['idDmCapHoc'] !== undefined) {
                this.idDmCapHoc = (params['idDmCapHoc'] - 0);
                this.isChecked = true;
            }
            if (params['query'] !== undefined) {
                this.query = params['query'];
                this.isChecked = true;
            }
        });
        await this.getDmKhoiLop();
        this._route.queryParams.subscribe(params => {
            if (params['idDmKhoiLop'] !== undefined) {
                this.idDmKhoiLop = (params['idDmKhoiLop'] - 0);
                this.isChecked = true;
            }

        });
        await this.getDmMonHoc();
        this._route.queryParams.subscribe(params => {
            if (params['idDmMonHoc'] !== undefined) {
                this.idDmMonHoc = (params['idDmMonHoc'] - 0);
                this.isChecked = true;
            }
        });
    }
    async getDmCapHoc() {
        this.dsCapHoc = [{ label: 'Tất cả', value: 0 }];
        await this._dmCapHocService.getsAll(1).then(rs => {
            if (rs.success) {
                this.convertDataToOptions(this.dsCapHoc, rs.data, 'ten');
            }
        })
    }

    async getDmKhoiLop() {
        this.dsKhoiLop = [{ label: 'Tất cả', value: 0 }];
        this.idDmKhoiLop = 0;
        if (this.idDmCapHoc > 0) {
            await this._dmKhoiLopService.getsByIdDmCapHoc(this.idDmCapHoc).then(rs => {
                if (rs.success) {
                    this.convertDataToOptions(this.dsKhoiLop, rs.data, 'ten');
                }
            })
        }
    }

    async getDmMonHoc() {
        this.dsMonHoc = [{ label: 'Tất cả', value: 0 }];
        this.idDmMonHoc = 0;
        if (this.idDmKhoiLop > 0) {
            var model = {
                keyword: '',
                trangThai: 1,
                idDmCapHoc: this.idDmCapHoc,
                idDmChuongTrinhHoc: 0,
                idDmKhoiLop: this.idDmKhoiLop,
                pageIndex: 1,
                pageSize: 9999,
                orderCol: 'ten',
                isDesc: false
            }
            await this._dmMonHocService.find(model).then(rs => {
                if (rs.success) {
                    this.convertDataToOptions(this.dsMonHoc, rs.data, 'ten');
                }
            })
        }
    }

    onChangeCapHoc() {
        console.log('onChangeCapHoc ' + this.idDmCapHoc);
        this.getDmKhoiLop();
        this.getDmMonHoc();
    }
    onChangeKhoiLop() {
        this.getDmMonHoc();
    }

    convertDataToOptions(options: any[], data: any[], fieldName: string) {
        if (data.length > 0) {
            data.map(item =>
                options.push({ label: item[fieldName], value: (item.id - 0) })
            );
        }
    }

    onGoSearch() {
        var data = {
            query: this.query,
            idDmCapHoc: this.idDmCapHoc,
            idDmKhoiLop: this.idDmKhoiLop,
            idDmMonHoc: this.idDmMonHoc
        }
        this.goSearch.next(data);
    }

}
