import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { environment } from '../../../../../src/environments/environment';

@Pipe({
    name: 'icon'
})
export class IconPipe implements PipeTransform {
    constructor(protected sanitizer: DomSanitizer) { }
    transform(fileName: string, isFolder?: boolean, webPath?: string) {
        if (!fileName) {
            return '';
        }
        if (!isFolder) {
            isFolder = false;
        }

        if (isFolder) {
            return this.sanitizer.bypassSecurityTrustHtml(`<svg focusable="false" data-prefix="fas" data-icon="folder" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="folder-icon svg-inline--fa fa-folder fa-w-16"><path  fill="currentColor" d="M464 128H272l-64-64H48C21.49 64 0 85.49 0 112v288c0 26.51 21.49 48 48 48h416c26.51 0 48-21.49 48-48V176c0-26.51-21.49-48-48-48z" class=""></path></svg>`);
        } else {
            const fileExtArr = fileName.split('.');
            const fileExt = fileExtArr[fileExtArr.length - 1];
            var isImage = this.isImgFile(fileExt);
            if (isImage) {
                return this.sanitizer.bypassSecurityTrustHtml(`<img loading="lazy" src="${environment.apiDomain.svFsSystemEndpoint}${webPath}" alt="Eggcited" class="image">`);
            } else {
                return this.sanitizer.bypassSecurityTrustHtml(`
                <span data-v-0f334d8c="" class="file-icon-text">
                ${fileExt}
                </span>
                <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="file" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512" class="file-icon svg-inline--fa fa-file fa-w-12">
                <path  fill="currentColor" d="M224 136V0H24C10.7 0 0 10.7 0 24v464c0 13.3 10.7 24 24 24h336c13.3 0 24-10.7 24-24V160H248c-13.2 0-24-10.8-24-24zm160-14.1v6.1H256V0h6.1c6.4 0 12.5 2.5 17 7l97.9 98c4.5 4.5 7 10.6 7 16.9z" class="">
                </path>
                </svg>`);
            }
        }
    }

    isImgFile(_fileExt: string): boolean {
        var imgExts = ['jpg', 'jpeg', 'gif', 'png', 'bmp'];
        if (imgExts.findIndex(x => x == _fileExt) >= 0) {
            return true;
        } else {
            return false;
        }
    }

}
