import { Injectable, Injector } from '@angular/core';
import { SvUser } from '../models/sv-user';
import { OAuthService } from 'angular-oauth2-oidc';
import * as JWT from 'jwt-decode';
import { BasicUserInfo } from '../models/basic-user-info';
import { environment } from '../../../../../src/environments/environment';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { shareReplay, retry, catchError, map } from 'rxjs/operators';
import { ResponseResult } from '../models/response-result';
import { SelectItem } from 'primeng/api';
import { BaseService } from './base.service';
import { ExceptionHandlerService } from './exception-handler.service';
import { ExportService } from './export.service';

@Injectable()
export class UserService extends BaseService {
    readonly USER_INFO_KEY = 'user_info';
    readonly RETRY_COUNT: number = 0;
    readonly REPLAY_COUNT: number = 10;
    readonly DEFAULT_AVATAR_MALE = environment.appMetadata.main.defaultMaleAvatar;
    readonly DEFAULT_AVATAR_FEMALE = environment.appMetadata.main.defaultFemaleAvatar;
    readonly authenticationEnpoint = `${environment.apiDomain.coreEndPoint}`;
    readonly storage = sessionStorage;

    public tokenReceived = new Observable<any>();
    // constructor(
    //     private _oauthService: OAuthService,
    //     private _http        : HttpClient,
    //     private _injector    : Injector
    // ) {

    // }
    constructor(http: HttpClient,
        injector: Injector,
        private _oauthService: OAuthService,
        private _exportService: ExportService,
        private _exceptionHandlerService: ExceptionHandlerService) {
        super(http, injector, `${environment.apiDomain.coreEndPoint}/users`);
    }

    cachedUserInfo: SvUser;

    promises = [];
    isReady = false;

    returnPromises(): void {
        while (this.promises.length > 0) {
            const pr = this.promises.pop();
            const accessToken = this._oauthService.getAccessToken();
            const decoded: any = JWT(accessToken);
            const user = new SvUser();

            user.userId = decoded.sub;
            user.avatar = decoded.avatar;
            user.fullName = decoded.fullname;
            user.typeId = decoded.typeId;
            user.idDmTruongHoc = decoded.idDmTruongHoc;
            user.idDmPhanTo = decoded.idDmPhanTo;

        }
        this.isReady = true;
    }

    getForAutoComplete(key: string): Promise<ResponseResult> {
        const apiUrl = `${this.serviceUri}/GetForAutoComplete?key=${key}`;

        return this._http.get<ResponseResult>(apiUrl)
            .pipe(
                catchError((err: HttpErrorResponse) => this._exceptionHandlerService.handleError(err, this._injector))
            ).toPromise();
    }

    getCurrentUser(): Promise<SvUser> {
        return new Promise((resolve, reject) => {
            if (!this.isReady) {
                this.promises.push([resolve, reject]);
            } else {
                const key = 'AUTHORIZATION.USERINFO';
                const storageItem = this.storage.getItem(key);
                if (storageItem) {
                    const result = JSON.parse(storageItem) as SvUser;
                    resolve(result);
                } else {
                    const accessToken = this._oauthService.getAccessToken();
                    const decoded: any = JWT(accessToken);

                    const user = new SvUser();
                    user.issuperuser = decoded.issuperuser;
                    user.userId = decoded.userid;
                    user.avatar = decoded.avatar;
                    user.displayName = decoded.displayname;
                    user.typeId = decoded.typeId;
                    user.idDmTruongHoc = decoded.idDmTruongHoc;
                    user.idDmPhanTo = decoded.idDmPhanTo;
                    user.idDmCapHoc = decoded.idDmCapHoc;
                    this.storage.setItem(key, JSON.stringify(user));
                    resolve(user);

                    // this.getStaffInfo(decoded.sub).then(rs1 => {

                    //     const user = new SvUser();
                    //     if (rs1) {
                    //         user.issuperuser = decoded.issuperuser;
                    //         user.userId = decoded.sub;
                    //         user.avatar = rs1.avatar;
                    //         user.fullName = rs1.fullName;
                    //         user.position = rs1.position;
                    //         user.role = rs1.role;

                    //         if (!user.avatar || user.avatar === '') {
                    //             if (user.gender === 0) {
                    //                 user.avatar = this.DEFAULT_AVATAR_FEMALE;
                    //             } else {
                    //                 user.avatar = this.DEFAULT_AVATAR_MALE;
                    //             }
                    //         }

                    //         this.cachedUserInfo = user;
                    //     }
                    //     this.storage.setItem(key, JSON.stringify(user));
                    //     resolve(user);
                    // }, err => {
                    //     reject(err);
                    // });
                }
            }
        });
    }



    async getId(): Promise<number> {
        if (this.cachedUserInfo) {
            return new Promise<number>((resolve, reject) => {
                resolve(this.cachedUserInfo.userId);
            });
        } else {
            return (await this.getCurrentUser()).userId;
        }
    }

    getBasicUserInfo(userId: number): Promise<BasicUserInfo> {

        // const storageItem = localStorage.getItem(this.USER_INFO_KEY);
        // const userInfos   = <BasicUserInfo[]>JSON.parse(storageItem);

        // const user = userInfos ? userInfos.find(item => item.id === userId) : null;
        // if (user) {
        //     return new Promise((resolve, reject) => resolve(user));
        // } else {
        return new Promise(async (resolve, reject) => {
            if (userId == null) {
                reject(<BasicUserInfo>{});
            } else {
                const svUrl = `${this.serviceUri}/GetBasicUserInfo?id=${userId}`;
                const result = await this._http.get<ResponseResult>(svUrl).pipe(
                    shareReplay(this.REPLAY_COUNT),
                    retry(this.RETRY_COUNT),
                    map(repsonse => {
                        // save response to local storage here
                        localStorage.setItem(this.USER_INFO_KEY, JSON.stringify(repsonse.data));

                        return <BasicUserInfo>repsonse.data;
                    },
                        catchError((err: HttpErrorResponse) => this.handleError(err, this._injector))
                    )).toPromise();

                resolve(result);
            }
        });
        // }
    }

    getBasicUsersInfo(userIds: string): Promise<BasicUserInfo[]> {
        const storageItem = localStorage.getItem(this.USER_INFO_KEY);
        const userInfos = <BasicUserInfo[]>JSON.parse(storageItem);

        // const users = userInfos ? userInfos.filter(item => userIds.indexOf(`,${item.id},`) > -1) : null;
        const check = userInfos ? userIds.indexOf(userInfos['id']) > -1 : null;
        if (check) {
            return new Promise((resolve, reject) => resolve(userInfos));
        } else {
            return new Promise((resolve, reject) => {
                const svUrl = `${this.serviceUri}/GetBasicUsersInfo?userIds=${userIds}`;
                this._http.get<ResponseResult>(svUrl).pipe(
                    shareReplay(this.REPLAY_COUNT),
                    retry(this.RETRY_COUNT),
                    catchError((err: HttpErrorResponse) => this.handleError(err, this._injector)),
                    map(repsonse => <BasicUserInfo[]>repsonse.data)
                ).toPromise().then(success => {
                    resolve(success);
                }).catch(err => {
                    reject(err);
                });
            });
        }
    }

    convertListUserIdToString(userIds: number[]): string {
        if (userIds.length > 0) {
            return `,${userIds.join(',')},`;
        }
        return ',';
    }

    fillBasicUserInfo(dataSource: any[], propertyName: string, users: BasicUserInfo[]): any[] {
        dataSource.forEach(item => {
            const userId = item[propertyName].id ? item[propertyName].id : item[propertyName];
            // tslint:disable-next-line:no-shadowed-variable
            const user = users.find(user => user.id === userId);
            if (user) {
                item[propertyName] = user;
            }
        });

        return dataSource;
    }


    restorePassword(item: any) {
        return this._http.put<ResponseResult>(`${this.serviceUri}/restorePassword`, item)
            .pipe(catchError(err => this.handleError(err, this._injector)))
            .toPromise();
    }


    getInSelectItem(): Promise<SelectItem[]> {
        return new Promise((resolve, reject) => {
            this.get('')
                .then(userResponse => {
                    const staffs = [];
                    userResponse.data.forEach(element => {
                        staffs.push({ value: element.id, label: element.fullName });
                    });
                    resolve(staffs);
                }, error => {
                    reject(error);
                });
        });
    }

    changePassword(item: any): Promise<ResponseResult> {

        const apiUrl = `${this.serviceUri}/ChangePassword`;
        return this._http
            .post<ResponseResult>(apiUrl, item)
            .pipe(catchError(err => this.handleError(err, this._injector)))
            .toPromise();
    }

    GetsByManagerIdArrayId(id: string): Promise<ResponseResult> {
        const apiUrl = `${this.serviceUri}/GetLowerStaffsByUserId?userId=${id}`;
        return this.defaultGet(apiUrl);
    }

    markAsAdmin(userId: number, isAdmin: boolean) {
        const url = `${this.serviceUri}/markAsAdmin/${userId}/${isAdmin}`;
        return this._http
            .put<ResponseResult>(url, isAdmin)
            .pipe(catchError(err => this.handleError(err, this._injector)))
            .toPromise();
    }

    markAsRepresent(userId: number, isRepresent: number) {
        const url = `${this.serviceUri}/markAsRepresent/${userId}/${isRepresent}`;

        return this._http
            .put<ResponseResult>(url, isRepresent)
            .pipe(catchError(err => this.handleError(err, this._injector)))
            .toPromise();
    }

    getDefaultAvatar() {
        return this.DEFAULT_AVATAR_MALE;
    }

    handleError(error: any, injector: Injector) {
        // console.error('Có lỗi xảy ra', error);
        if (error.status === 401) {
            // const authenService = injector.get(AuthenService);
            // authenService.logout();
            console.log('error 401');
        } else {
        }
        return Promise.reject(error);
    }

    find(model: any): Promise<ResponseResult> {
        const apiUrl = `${this.serviceUri}/find`;
        return this._http
            .post<ResponseResult>(apiUrl, model)
            .pipe(catchError(err => this.handleError(err, this._injector)))
            .toPromise();
    }

    logOut(): Promise<ResponseResult> {
        const apiUrl = `${this.serviceUri}/LogOut`;
        return this._http
            .post<ResponseResult>(apiUrl, true)
            .pipe(catchError(err => this.handleError(err, this._injector)))
            .toPromise();
    }

    importUsers(idDmTruongHoc: number, item: any): Promise<ResponseResult> {
        const url = `${this.serviceUri}/ImportUsers/${idDmTruongHoc}`;
        return this._http.post<ResponseResult>(url, item)
            .pipe(catchError(err => this.handleError(err, this._injector))).toPromise();
    }

    insertMany(item: any): Promise<ResponseResult> {
        const url = `${this.serviceUri}/InsertMany`;
        return this._http.post<ResponseResult>(url, item)
            .pipe(catchError(err => this.handleError(err, this._injector))).toPromise();
    }
    getTemplateImportUsers(dmData: any) {
        const url = `${this.serviceUri}/GetTemplateImportUsers`;
        return this._http
            .post(`${url}`, dmData, { responseType: 'blob' as 'json' })
            .pipe(catchError((err: HttpErrorResponse) => this.handleError(err, this._injector)))
            .subscribe(res => {
                this._exportService.saveAsExcelFile(res, 'users_import');
            });
    }
    getMyInfo(): Promise<ResponseResult> {
        const url = `${this.serviceUri}/GetMyInfo`;
        return this.defaultGet(url);
    }

    updateMyInfo(item: any): Promise<ResponseResult> {
        const url = `${this.serviceUri}/UpdateMyInfo`;
        return this._http.put<ResponseResult>(url, item).toPromise();
    }

    changeMyPassword(item: any): Promise<ResponseResult> {
        const apiUrl = `${this.serviceUri}/ChangeMyPassword`;
        return this._http
            .post<ResponseResult>(apiUrl, item)
            .pipe(catchError(err => this.handleError(err, this._injector)))
            .toPromise();
    }


    loginWithImplicit(param: any) {
        const apiUrl = `${environment.apiDomain.coreEndPoint}/connect/token`;
        return this._http
            .post<any>(apiUrl, param)
            .pipe(catchError(err => this.handleError(err, this._injector)))
            .toPromise();
    }

    getTeacherInfo(id: number): Promise<ResponseResult> {
        const url = `${this.serviceUri}/GetTeacherInfo/${id}`;
        return this.defaultGet(url);
    }

    getsTeacherByIdDmPhanTo(idDmTruongHoc: number, idDmPhanTo: number): Promise<ResponseResult> {
        const url = `${this.serviceUri}/GetsTeacherByIdDmPhanTo/${idDmTruongHoc}/${idDmPhanTo}`;
        return this.defaultGet(url);
    }
}
