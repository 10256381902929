import { Injectable, Injector } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BaseService, ResponseResult } from 'sv-shared';
import { catchError } from 'rxjs/operators';
import { environment } from '../../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class DmKhoiLopService extends BaseService {
    constructor(http: HttpClient, injector: Injector) {
        super(http, injector, `${environment.apiDomain.svmlearningEndpoint}/dmkhoilop`);
    }
    find(model: any): Promise<ResponseResult> {
        const apiUrl = `${this.serviceUri}/find`;
        return this._http
            .post<ResponseResult>(apiUrl, model)
            .pipe(catchError(err => this.handleError(err, this._injector)))
            .toPromise();
    }

    getsByIdDmCapHoc(idDmCapHoc: number = 0) {
        const apiUrl = `${this.serviceUri}/GetsByIdDmCapHoc/${idDmCapHoc}`;
        return this.defaultGet(apiUrl);
    }
}
