import { Injectable, Injector } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { BaseService, ResponseResult, ExportService } from 'sv-shared';
import { catchError } from 'rxjs/operators';
import { environment } from '../../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class DmMonHocService extends BaseService {
    constructor(
        http: HttpClient,
        private _exportService: ExportService,
        injector: Injector) {
        super(http, injector, `${environment.apiDomain.svmlearningEndpoint}/dmmonhoc`);
    }

    find(model: any): Promise<ResponseResult> {
        const apiUrl = `${this.serviceUri}/find`;
        return this._http
            .post<ResponseResult>(apiUrl, model)
            .pipe(catchError(err => this.handleError(err, this._injector)))
            .toPromise();
    }

    exportDsMonHoc(model: any) {
        const apiUrl = `${this.serviceUri}/ExportDsMonHoc`;
        return this._http
            .post(`${apiUrl}`, model, { responseType: 'blob' as 'json' })
            .pipe(catchError((err: HttpErrorResponse) => this.handleError(err, this._injector)))
            .subscribe(res => {
                this._exportService.saveAsExcelFile(res, 'dsMonhoc');
            });
    }
}
