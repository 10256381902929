<!-- Banner -->
<div class="elh-banner style-3" id="elh-banner">
    <p-carousel #clBanner [value]="dsBanner" [numVisible]="1" [numScroll]="1" [autoplayInterval]="5000"
        [circular]="true" class="owl-carousel" [showNavigators]="false">
        <ng-template let-banner pTemplate="item">
            <div class="elh-banner-item">
                <img class="image-entity" src="{{banner.imgUrl}}" style="opacity: 1;">
                <div class="banner-content">
                    <div class="container">
                        <div class="row">
                            <div class="col-xs-12">
                                <h3 class="elh-banner-title" style="font-size: 3.2rem !important;"
                                    *ngIf="banner.ten != null || banner.ten != undefined">
                                    {{banner.ten}}</h3>
                                <h4 class="elh-banner-subtitle"
                                    *ngIf="banner.noiDung != null || banner.noiDung != undefined">
                                    {{banner.noiDung}}</h4><br>
                                <div class="elh-button-group">
                                    <a class="btn" target="_blank" href="{{banner.linkUrl}}">
                                        Xem chi tiết
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </ng-template>
        <p-footer>
            <div class="owl-controls clickable">
                <div class="owl-buttons">
                    <div class="owl-prev" (click)="clBannerPrev()"><i class="fa fa-angle-left"></i></div>
                    <div class="owl-next" (click)="clBannerNext()"><i class="fa fa-angle-right"></i></div>
                </div>
            </div>
        </p-footer>
    </p-carousel>
</div>
<!-- Banner End -->

<!-- Main wrapper start -->
<div class="elh-main-wrap">

    <!-- Registration -->
    <div class="elh-section-padding-big elh-bottom-0">
        <app-courses-boxsearch (goSearch)="onGoSearch($event)"></app-courses-boxsearch>
    </div>
    <!-- Registration End -->

    <!-- Popular Courses -->
    <div class="elh-section elh-section-padding-small elh-carousel-section pop-section" *ngIf="dsTieuHoc.length > 0">
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <div class="elh-section-header text-left">
                        <h3 class="elh-section-title u">Cấp <span>Tiểu học</span></h3>
                        <p class="elh-section-text">Các khóa học cấp tiểu học</p>
                        <a class="all-link" [routerLink]="['/course']" [queryParams]="{act: search, idDmCapHoc: 1}">Xem
                            toàn bộ</a>
                    </div>
                </div>
            </div>
            <div class="row">
                <p-carousel #tieuHoc [value]="dsTieuHoc" [numVisible]="3" [numScroll]="1"
                    [responsiveOptions]="responsiveOptions" [circular]="true" [showNavigators]="false"
                    class="elh-generic-carousel owl-carousel owl-theme">
                    <ng-template let-course pTemplate="item">
                        <div class="elh-course">
                            <a class="elh-course-thumb" routerLink="/course/{{course.id}}">
                                <img class="img-responsive"
                                    src="{{course.imageUrl != null ? (course.imageUrl | thumbor : '348x207') : 'assets/images/course/1.jpg'}}"
                                    alt="...">
                            </a>
                            <div class="elh-course-content">
                                <h4 class="elh-course-title"><a routerLink="/course/{{course.id}}">{{course.ten}}</a>
                                </h4>
                            </div>
                            <a routerLink="/teacher/{{course.userIdGiaoVien}}" class="elh-course-instructor">
                                <div class="elh-instructor-thumb">
                                    <img class="img-responsive"
                                        src="{{course.avatarGiaoVien != null ? course.avatarGiaoVien : 'assets/images/instructor/small-1.jpg'}}"
                                        alt="...">
                                </div>
                                <h5 class="elh-instructor-name">{{course.displayNameGiaoVien}}</h5>
                            </a>
                            <div class="elh-course-footer">
                                <p class="elh-course-price">
                                    <span class="elh-price-now">{{course.price}}</span>
                                </p>
                                <p class="elh-course-metas">
                                    <span class="elh-student-count"><i class="fa fa-users"></i>
                                        {{course.countCourseUser}}</span>
                                    <span class="elh-comment-count"><i class="fa fa-comments"></i>
                                        {{course.countCourseComment}}</span>
                                </p>
                            </div>
                        </div>
                    </ng-template>
                    <p-footer>
                        <div class="owl-controls clickable">
                            <div class="owl-buttons">
                                <div class="owl-prev" (click)="tieuhocPrev()"><i class="fa fa-angle-left"></i></div>
                                <div class="owl-next" (click)="tieuhocNext()"><i class="fa fa-angle-right"></i></div>
                            </div>
                        </div>
                    </p-footer>
                </p-carousel>
            </div>
        </div>
    </div>
    <div class="elh-section elh-section-padding-small elh-carousel-section pop-section pop-section--block"
        *ngIf="dsThcs.length > 0">
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <div class="elh-section-header text-left">
                        <h3 class="elh-section-title u">Cấp <span>Trung học cơ sở</span></h3>
                        <p class="elh-section-text">Các khóa học cấp Trung học cơ sở</p>
                        <a class="all-link" [routerLink]="['/course']" [queryParams]="{act: search, idDmCapHoc: 2}">Xem
                            toàn bộ</a>
                    </div>
                </div>
            </div>
            <div class="row">
                <p-carousel #thcs [value]="dsThcs" [numVisible]="3" [numScroll]="1" [showNavigators]="false"
                    [responsiveOptions]="responsiveOptions" [circular]="true"
                    class="elh-generic-carousel owl-carousel owl-theme">
                    <ng-template let-course pTemplate="item">
                        <div class="elh-course">
                            <a class="elh-course-thumb" routerLink="/course/{{course.id}}">
                                <img class="img-responsive"
                                    src="{{course.imageUrl != null ? (course.imageUrl | thumbor : '348x207') : 'assets/images/course/1.jpg'}}"
                                    alt="...">
                            </a>
                            <div class="elh-course-content">
                                <h4 class="elh-course-title"><a routerLink="/course/{{course.id}}">{{course.ten}}</a>
                                </h4>
                            </div>
                            <a routerLink="/teacher/{{course.userIdGiaoVien}}" class="elh-course-instructor">
                                <div class="elh-instructor-thumb">
                                    <img class="img-responsive"
                                        src="{{course.avatarGiaoVien != null ? course.avatarGiaoVien : 'assets/images/instructor/small-1.jpg'}}"
                                        alt="...">
                                </div>
                                <h5 class="elh-instructor-name">{{course.displayNameGiaoVien}}</h5>
                            </a>
                            <div class="elh-course-footer">
                                <p class="elh-course-price">
                                    <span class="elh-price-now">{{course.price}}</span>
                                </p>
                                <p class="elh-course-metas">
                                    <span class="elh-student-count"><i class="fa fa-users"></i>
                                        {{course.countUser}}</span>
                                    <span class="elh-comment-count"><i class="fa fa-comments"></i>
                                        {{course.countComment}}</span>
                                </p>
                            </div>
                        </div>
                    </ng-template>
                    <p-footer>
                        <div class="owl-controls clickable">
                            <div class="owl-buttons">
                                <div class="owl-prev" (click)="thcsPrev()"><i class="fa fa-angle-left"></i></div>
                                <div class="owl-next" (click)="thcsNext()"><i class="fa fa-angle-right"></i></div>
                            </div>
                        </div>
                    </p-footer>
                </p-carousel>
            </div>
        </div>
    </div>
    <div class="elh-section elh-section-padding-small elh-carousel-section pop-section" *ngIf="dsThpt.length > 0">
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <div class="elh-section-header text-left">
                        <h3 class="elh-section-title u">Cấp <span>Trung học phổ thông</span></h3>
                        <p class="elh-section-text">Các khóa học Trung học phổ thông</p>
                        <a class="all-link" [routerLink]="['/course']" [queryParams]="{act: search, idDmCapHoc: 3}">Xem
                            toàn bộ</a>
                    </div>
                </div>
            </div>
            <div class="row">
                <p-carousel #thpt [value]="dsThpt" [numVisible]="3" [numScroll]="1" [showNavigators]="false"
                    [responsiveOptions]="responsiveOptions" [circular]="true"
                    class="elh-generic-carousel owl-carousel owl-theme">
                    <ng-template let-course pTemplate="item">
                        <div class="elh-course">
                            <a class="elh-course-thumb" routerLink="/course/{{course.id}}">
                                <img class="img-responsive"
                                    src="{{course.imageUrl != null ? (course.imageUrl | thumbor : '348x207') : 'assets/images/course/1.jpg'}}"
                                    alt="...">
                            </a>
                            <div class="elh-course-content">
                                <h4 class="elh-course-title"><a routerLink="/course/{{course.id}}">{{course.ten}}</a>
                                </h4>
                            </div>
                            <a routerLink="/teacher/{{course.userIdGiaoVien}}" class="elh-course-instructor">
                                <div class="elh-instructor-thumb">
                                    <img class="img-responsive"
                                        src="{{course.avatarGiaoVien != null ?(course.avatarGiaoVien | thumbor : '60x60') : 'assets/images/instructor/small-1.jpg'}}"
                                        alt="...">
                                </div>
                                <h5 class="elh-instructor-name">{{course.displayNameGiaoVien}}</h5>
                            </a>
                            <div class="elh-course-footer">
                                <p class="elh-course-price">
                                    <span class="elh-price-now">{{course.price}}</span>
                                </p>
                                <p class="elh-course-metas">
                                    <span class="elh-student-count"><i class="fa fa-users"></i>
                                        {{course.countUser}}</span>
                                    <span class="elh-comment-count"><i class="fa fa-comments"></i>
                                        {{course.countComment}}</span>
                                </p>
                            </div>
                        </div>
                    </ng-template>
                    <p-footer>
                        <div class="owl-controls clickable">
                            <div class="owl-buttons">
                                <div class="owl-prev" (click)="thptPrev()"><i class="fa fa-angle-left"></i></div>
                                <div class="owl-next" (click)="thptNext()"><i class="fa fa-angle-right"></i></div>
                            </div>
                        </div>
                    </p-footer>
                </p-carousel>
            </div>
        </div>
    </div>
    <!-- Popular Courses End -->

    <div class="elh-section elh-img-bg elh-bg-4 mini-section"
        style="background-image: url(./assets/images/main/bg-truong-2.jpg);">
        <div class="elh-overlay elh-section-padding-big">
            <div class="container">
                <div class="row">
                    <div class="col-md-5 col-sm-6">
                        <div class="elh-header-left-style">
                            <h3>Phản hồi</h3>
                            <p>Chúng tôi ghi nhận lại những ý kiến phản hồi từ phụ huynh</p>
                        </div>
                    </div>
                    <div class="col-sm-6 col-md-offset-1">
                        <p-carousel [value]="dsComment" [numVisible]="1" [numScroll]="1" [autoplayInterval]="3000"
                            [showNavigators]="false" [circular]="true" class="owl-carousel instructor-comments">
                            <ng-template let-comment pTemplate="item">
                                <div class="instructor-comment">
                                    <div class="instructor-thumb">
                                        <img class="img-responsive" src="{{comment.avatarUrl}}" alt="...">
                                    </div>
                                    <blockquote>{{comment.contents}}</blockquote>
                                    <h5 class="instructor-name">{{comment.displayName}}</h5>
                                    <p class="instructor-field">{{comment.roleName}}</p>
                                </div>
                            </ng-template>
                        </p-carousel>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- Our instructor -->
    <!-- Our instructor end -->

    <!-- Partner Logoes -->
    <div class="elh-section elh-section-padding-big">
        <div class="elh-section-header text-center">
            <h3 class="elh-section-title"><span>Đối tác</span></h3>
            <p class="elh-section-text">Chúng tôi phối hợp với những đối tác hàng đầu trong lĩnh vực giáo dục</p>
        </div>
        <div class="container">
            <div class="partner-lgs text-center">
                <a target="_blank" class="partner-lg" href="{{item.linkUrl}}" *ngFor="let item of dsCustomer">
                    <img src="{{item.imgUrl}}" alt="{{item.ma}}" title="{{item.ma}}">
                </a>
            </div>
        </div>
    </div>
    <!-- Partner Logoes End -->

    <!-- Subscribe us -->
    <div class="elh-section elh-theme-bg elh-subscribe-section">
        <div class="container">
            <div class="row">
                <div class="col-sm-6">
                    <div class="elh-subscribe-heading">
                        <h3 class="mb10">Đăng ký</h3>
                        <h5>Để nhận những tin tức mới nhất từ hệ thống giáo dục Edison</h5>
                    </div>
                </div>
                <div class="col-sm-6">
                    <form #formElement [formGroup]="formGroup" class="elh-subscribe-form pull-right xs-pull-center">
                        <input type="email" name="email" placeholder="Nhập email" [(ngModel)]="email"
                            formControlName="email">
                        <button type="submit" style="cursor: pointer;" (click)="onAddEmail()"
                            [disabled]="email == ''">Đăng ký</button>
                    </form>
                </div>
            </div>
        </div>
    </div>
    <!-- Subscribe us end -->

</div>
<!-- Main wrapper start end -->
