<div class="sv-page-content-area__body">
    <div class="sv-data-view-area">
        <div class="sv-data-view-area__main-block">
            <div class="sv-data-view-main-action-area">
                <div class="sv-data-view-main-action-area" style="flex: 1 1 400px">
                    <button type="button" (click)="goHome()" id="act-refresh"
                        class="__data-tree-tool-toggle sv-btn -icon-only sv-bgc--gray-10 sv-bgc--gray-20--hover sv-bgc--gray-30--active sv-bgc--gray-30--activated h-easing--flash sv-icon-color--primary"
                        pTooltip="{{'Thư mục gốc' | translate }}">
                        <span class="__icon ci ci-home"></span>
                    </button>
                    <button type="button" (click)="addFolder()" id="act-add"
                        class="sv-btn sv-bgc--secondary sv-bgc--secondary-alt--hover sv-bgc--secondary-alt-2--active h-easing--flash">
                        <span class="__label">{{'Thêm thư mục mới' | translate}}</span>
                        <span class="__icon ci ci-add"></span>
                    </button>
                    <button type="button" (click)="uploadFiles()" *ngIf="root > 0"
                        class="sv-btn sv-bgc--transparent sv-bgc--gray-20--hover sv-bgc--gray-30--active easing-fast sv-icon-color--green-50 focus-style-1">
                        <span class="__label">{{'Tải file lên' | translate}}</span>
                        <span class="__icon ci ci-upload"></span>
                    </button>
                    <button type="button" (click)="uploadFolder()" *ngIf="root > 0"
                        class="sv-btn sv-bgc--transparent sv-bgc--gray-20--hover sv-bgc--gray-30--active easing-fast sv-icon-color--green-50 focus-style-1">
                        <span class="__label">{{'Tải folder lên' | translate}}</span>
                        <span class="__icon ci ci-upload"></span>
                    </button>
                </div>
            </div>
            <div class="sv-table-wrapper">
                <div class="file-grid-wrapper">
                    <div class="file-list grid">
                        <div class="file-wrapper file-item" *ngFor="let item of dataSource">
                            <div class="file-item" (contextmenu)="showContectMenu($event, item)"
                                (click)="onClickFsItem(item)" [class.is-clicked]="item.isClick"
                                (dblclick)="onDoubleItemClick(item)">
                                <div class="icon-item"
                                    [innerHTML]="item.ten | icon : item.idFsItemType == 1 : item.webPath">
                                </div>
                                <div class="item-name">
                                    <b class="name">
                                        {{item.ten}}
                                    </b>
                                    <div class="item-info">
                                        <span class="item-length" *ngIf="item.countShared > 0">
                                            {{item.countShared}}
                                        </span>
                                        <div class="item-shared" *ngIf="item.countShared > 0">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="12px" height="12px"
                                                viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
                                                stroke-linecap="round" stroke-linejoin="round"
                                                class="shared-icon feather feather-link">
                                                <path d="M10 13a5 5 0 0 0 7.54.54l3-3a5 5 0 0 0-7.07-7.07l-1.72 1.71">
                                                </path>
                                                <path d="M14 11a5 5 0 0 0-7.54-.54l-3 3a5 5 0 0 0 7.07 7.07l1.71-1.71">
                                                </path>
                                            </svg>
                                        </div>
                                        <span class="item-length" *ngIf="item.idFsItemType > 1">
                                            {{item.totalSize | fileSize}}
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <p-overlayPanel #pContext [hideTransitionOptions]="'0ms'" [showTransitionOptions]="'0ms'"
                    appendTo="body" styleClass="contextmenu">
                    <div class="menu-options">
                        <ul class="menu-option-group">
                            <li class="menu-option" (click)="viewFile()">
                                <div class="icon ci ci-view"></div>
                                <div class="text-label">
                                    Xem
                                </div>
                            </li>
                            <li class="menu-option" (click)="mp4Encode()" [hidden]="selectedFsItem?.idFsItemType != 21">
                                <div class="icon ci ci-data-vis-1"></div>
                                <div class="text-label">
                                    Mã hóa video mp4
                                </div>
                            </li>
                            <li class="menu-option" (click)="copyUrlAndClose()"
                                [hidden]="selectedFsItem?.idFsItemType <= 1">
                                <div class="icon ci ci-copy"></div>
                                <div class="text-label">
                                    Copy Link
                                </div>
                            </li>
                            <!---->
                        </ul>
                        <ul class="menu-option-group">
                            <li class="menu-option" (click)="unzipItem()" [hidden]="selectedFsItem?.idFsItemType != 17">
                                <div class="icon ci ci-document-export"></div>
                                <div class="text-label">
                                    Giải nén
                                </div>
                            </li>
                            <li class="menu-option" (click)="shareItem()">
                                <div class="icon ci ci-share"></div>
                                <div class="text-label">
                                    Chia sẻ
                                </div>
                            </li>
                            <li class="menu-option" (click)="editFsItem()" *ngIf="selectedFsItem?.idFsItemType == 1">
                                <div class="icon ci ci-edit">
                                </div>
                                <div class="text-label ">
                                    Sửa tên
                                </div>
                            </li>
                            <li class="menu-option" (click)="deleteFsItem()" authorize="[{'svfssystem':'fsfolder/11'}]">
                                <div class="icon ci ci-delete sv-color--red-60">
                                </div>
                                <div class="text-label sv-color--red-60">
                                    Xóa
                                </div>
                            </li>
                        </ul>
                    </div>
                </p-overlayPanel>
            </div>
        </div>
    </div>
</div>

<app-fssytem-folder-form #pEditFolder (closePopup)="onPopupClosed1($event)"></app-fssytem-folder-form>
<app-fssystem-file-upload #pUpload (closePopup)="onPopupClosed($event)"></app-fssystem-file-upload>
<app-fssystem-file-viewer #pFileViewer></app-fssystem-file-viewer>
<app-fssystem-item-shared #pShared (closePopup)="onPopupClosed1($event)"></app-fssystem-item-shared>
<app-fssytem-folder-upload #pUploadFolder (closePopup)="onPopupClosed1($event)"></app-fssytem-folder-upload>
