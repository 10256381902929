import { Injectable, Injector } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BaseService, ResponseResult } from 'sv-shared';
import { catchError } from 'rxjs/operators';
import { environment } from '../../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class CourseService extends BaseService {
    constructor(http: HttpClient, injector: Injector) {
        super(http, injector, `${environment.apiDomain.svmlearningEndpoint}/course`);
    }

    GetsByUserIdGiaoVien(idNamHoc: number, userIdGiaoVien: number, trangThai: number = 1): Promise<ResponseResult> {
        const apiUrl = `${this.serviceUri}/GetsByUserIdGiaoVien/${idNamHoc}/${userIdGiaoVien}/${trangThai}`;
        return this.defaultGet(apiUrl);
    }

    GetsMyCourse(model: any): Promise<ResponseResult> {
        const apiUrl = `${this.serviceUri}/GetsMyCourse`;
        return this._http
            .post<ResponseResult>(apiUrl, model)
            .pipe(catchError(err => this.handleError(err, this._injector))).toPromise();
    }

    getDetailForEdit(id: number): Promise<ResponseResult> {
        const apiUrl = `${this.serviceUri}/getDetailForEdit/${id}`;
        return this.defaultGet(apiUrl);
    }

    AddEditItem(item: any): Promise<ResponseResult> {
        const apiUrl = `${this.serviceUri}/AddEditItem`;
        return this._http
            .post<ResponseResult>(apiUrl, item)
            .pipe(catchError(err => this.handleError(err, this._injector))).toPromise();
    }

    getsMyStudyCourse(idNamHoc: number): Promise<ResponseResult> {
        const apiUrl = `${this.serviceUri}/GetsMyStudyCourse/${idNamHoc}`;
        return this.defaultGet(apiUrl);
    }

    getsMyStudyCourseWithUnfinishedTask(idNamHoc: number): Promise<ResponseResult> {
        const apiUrl = `${this.serviceUri}/GetsMyStudyCourseWithUnfinishedTask/${idNamHoc}`;
        return this.defaultGet(apiUrl);
    }

    find(searchModel: any): Promise<ResponseResult> {
        const apiUrl = `${this.serviceUri}/Find`;
        return this._http
            .post<ResponseResult>(apiUrl, searchModel)
            .pipe(catchError(err => this.handleError(err, this._injector))).toPromise();
    }

    getRandomByIdDmNamHoc(idNamHoc: number, idDmCapHoc: number, numOfRecord: number): Promise<ResponseResult> {
        const apiUrl = `${this.serviceUri}/GetRandomByIdDmNamHoc/${idNamHoc}/${idDmCapHoc}/${numOfRecord}`;
        return this.defaultGet(apiUrl);
    }

    checkIsTeacherViewerOfIdCourse(idCourse: number): Promise<ResponseResult> {
        const apiUrl = `${this.serviceUri}/CheckIsTeacherViewerOfIdCourse/${idCourse}`;
        return this.defaultGet(apiUrl);
    }

    getsFinishedCourse(model: any): Promise<ResponseResult> {
        const apiUrl = `${this.serviceUri}/GetsFinishedCourse`;
        return this._http
            .post<ResponseResult>(apiUrl, model)
            .pipe(catchError(err => this.handleError(err, this._injector))).toPromise();
    }
}
