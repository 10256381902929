import { RouterModule, Routes } from '@angular/router';
import { ModuleWithProviders } from '@angular/core';
import { QuicklinkStrategy } from 'ngx-quicklink';
import { DefaultLayoutComponent } from './layouts/default-layout/default-layout.component';
import { LearningLayoutComponent } from './layouts/learning-layout/learning-layout.component';
import { HomeComponent } from './specific-page/home/home.component';
import { SigninComponent } from './specific-page/signin/signin.component';
import { GuardIsLoginService, GuardIsTeacherService } from 'sv-shared';
import { AdminRedirectComponent } from './specific-page/admin-redirect/admin-redirect.component';
import { AppModule } from './app.module';

export const routes: Routes = [
    {
        path: '',
        component: DefaultLayoutComponent,
        children: [
            {
                path: '',
                component: HomeComponent
            },
            {
                path: 'signin',
                component: SigninComponent
            },
            {
                // canActivate: [GuardIsLoginService],
                path: 'profile', loadChildren: () => import('./sv-profile/sv-profile.module').then(m => m.SvProfileModule)
            },
            {
                path: 'teacher', loadChildren: () => import('./svmlearning/svteacher/svteacher.module').then(m => m.SvteacherModule)
            },
            {
                // canActivate: [GuardIsLoginService],
                path: 'study', loadChildren: () => import('./svmlearning/svstudent/svstudent.module').then(m => m.SvstudentModule)
            },
            {
                path: 'course', loadChildren: () => import('./svmlearning/courses/courses.module').then(m => m.CoursesModule)
            },
            {
                path: 'news', loadChildren: () => import('./svother/news/news.module').then(m => m.NewsModule)
            },
            {
                canActivate: [GuardIsLoginService],
                path: 'ilib', loadChildren: () => import('./svother/ilib/ilib.module').then(m => m.IlibModule)
            },
            {
                canActivate: [GuardIsTeacherService],
                path: 'interactive-video',
                loadChildren: () => import('./svmlearning/interactive-video/interactive-video.module').then(m => m.InteractiveVideoModule)
            },
            {
                path: 'admin',
                component: AdminRedirectComponent
            }
        ],
    },
    {
        canActivate: [GuardIsLoginService],
        path: 'learning',
        component: LearningLayoutComponent,
        children: [
            {
                path: '', loadChildren: () => import('./svmlearning/learning/learning.module').then(m => m.LearningModule)
            },
        ]
    },
    {
        path: '**',
        redirectTo: 'error/404'
    }
];

export const AppRoutes: ModuleWithProviders<AppModule> = RouterModule.forRoot(routes, { preloadingStrategy: QuicklinkStrategy });
