import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { CourseService } from '../../svmlearning/services/course.service';
import { DmNamhoc } from '../../svmlearning/models/dmnamhoc';
import { NotifierService } from 'sv-shared';
import { DmNamHocService } from '../../svmlearning/services/dm-nam-hoc.service';
import { Router } from '@angular/router';
import { CustomerService } from '../../svother/services/customer.service';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { WishlistService } from '../../svother/services/wishlist.service';
import { FeedbackService } from '../../svother/services/feedback.service';
import { BannerService } from '../../svother/services/banner.service';
import { Carousel } from 'primeng/carousel';

@Component({
    selector: 'app-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
    @ViewChild('tieuHoc') tieuHoc: Carousel;
    @ViewChild('thcs') thcs: Carousel;
    @ViewChild('thpt') thpt: Carousel;
    @ViewChild('clBanner') clBanner: Carousel;
    slickImgConfig = {
        slidesToShow: 1,
        slidesToScroll: 1,
        dots: false,
        arrows: false,
        autoplay: true,
    }

    currYear: DmNamhoc = new DmNamhoc();
    responsiveOptions = [];
    dsTieuHoc = [];
    dsThcs = [];
    dsThpt = [];
    dsCustomer = [];
    dsComment = [];
    email = '';
    formGroup = new UntypedFormGroup({
        email: new UntypedFormControl('', [
            Validators.required,
            Validators.email
        ]),
    });
    dsBanner = [];
    constructor(
        protected _courseService: CourseService,
        protected _dmNamHocService: DmNamHocService,
        protected _customerService: CustomerService,
        protected _notifierService: NotifierService,
        private _wishlistService: WishlistService,
        private _feedbackService: FeedbackService,
        protected _router: Router,
        protected _bannerService: BannerService,
    ) {
        this.responsiveOptions = [
            {
                breakpoint: '1199px',
                numVisible: 3,
                numScroll: 1
            },
            {
                breakpoint: '991px',
                numVisible: 2,
                numScroll: 1
            },
            {
                breakpoint: '579px',
                numVisible: 1,
                numScroll: 1
            }
        ];
    }

    async ngOnInit() {
        await this._dmNamHocService.GetSchoolYear().then(rs => {
            this.currYear = rs;
        })
        this.getBanner();
        this.getData();
        this.getDataDoiTac();
        this.getFeedback();
    }

    getBanner() {
        this.dsBanner = [];
        this._bannerService.get('', 1, 1, 99999, 'OrderNo', false).then(rs => {
            if (rs.success) {
                this.dsBanner = rs.data;
            }
        })
    }

    getData() {
        this._courseService.getRandomByIdDmNamHoc(this.currYear.id, 1, 12).then(rs => {
            if (rs.success) {
                this.dsTieuHoc = rs.data;
            }
        })
        this._courseService.getRandomByIdDmNamHoc(this.currYear.id, 2, 12).then(rs => {
            if (rs.success) {
                this.dsThcs = rs.data;
            }
        })
        this._courseService.getRandomByIdDmNamHoc(this.currYear.id, 3, 12).then(rs => {
            if (rs.success) {
                this.dsThpt = rs.data;
            }
        })
    }
    clBannerPrev() {
        this.clBanner.navBackward(null);
    }
    clBannerNext() {
        this.clBanner.navForward(null);
    }
    tieuhocPrev() {
        this.tieuHoc.navBackward(null);
    }

    tieuhocNext() {
        this.tieuHoc.navForward(null);
    }

    thcsPrev() {
        this.thcs.navBackward(null);
    }

    thcsNext() {
        this.thcs.navForward(null);
    }

    thptPrev() {
        this.thpt.navBackward(null);
    }

    thptNext() {
        this.thpt.navForward(null);
    }
    onGoSearch(data: any) {
        var url = `/course?act=search`;
        if (data.idDmCapHoc > 0) {
            url += `&idDmCapHoc=${data.idDmCapHoc}`
        }
        if (data.idDmKhoiLop > 0) {
            url += `&idDmKhoiLop=${data.idDmKhoiLop}`
        }
        if (data.idDmMonHoc > 0) {
            url += `&idDmMonHoc=${data.idDmMonHoc}`
        }
        if (data.query !== undefined && data.query !== '') {
            url += `&query=${data.query}`
        }
        this._router.navigateByUrl(url);
    }

    async getDataDoiTac() {
        var searchModel = {
            keyword: '',
            trangThai: 1,
            pageIndex: 1,
            pageSize: 9999,
            orderCol: 'Id',
            isDesc: false,
        };
        await this._customerService.getsBySearch(searchModel)
            .then(response => {
                this.dsCustomer = response.data;
                // response.data.forEach(item => {
                //     this.swiperCustomer.appendSlide([
                //         `<div class="swiper-slide swiper-slide-active" style="width: 190px; margin-right: 40px;"><a
                //         href="${item.linkUrl}" target="_blank"><img src="${item.imgUrl}"></a></div>`
                //     ]);
                // });
            });
        //this.swiperCustomer.update(true);

    }

    onAddEmail() {
        if (this.formGroup.invalid) {
            this._notifierService.showWarning('Bạn cần nhập đúng email của mình');
            return;
        }
        var model = {
            email: this.email
        }
        this._wishlistService.post(model).then(rs => {
            if (rs.success) {
                this._notifierService.showSuccess("Thêm email vào danh sách nhận tin thành công");
                this.email = '';
            }
        })
    }

    getFeedback() {
        var searchModel = {
            keyword: '',
            trangThai: 1,
            pageIndex: 1,
            pageSize: 20,
            orderCol: 'Id',
            isDesc: true,
        };
        this._feedbackService.getsBySearch(searchModel)
            .then(response => {
                this.dsComment = response.data;
            });
    }

}
