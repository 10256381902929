import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SvComponentModule } from 'sv-shared';
import { TranslateModule } from '@ngx-translate/core';
import { CoursesBoxsearchComponent } from './boxsearch/courses-boxsearch.component';
import { InteractiveVideoPlayerComponent } from './interactive-video-player/interactive-video-player.component';
import { ReactiveFormsModule } from '@angular/forms';
import { MathModule } from '../math/math.module';
import { VideoPlayerComponent } from './video-player/video-player.component';

@NgModule({
    imports: [
        CommonModule,
        SvComponentModule,
        TranslateModule,
        ReactiveFormsModule,
        MathModule
    ],
    declarations: [
        CoursesBoxsearchComponent,
        InteractiveVideoPlayerComponent,
        VideoPlayerComponent
    ],
    exports: [
        CoursesBoxsearchComponent,
        InteractiveVideoPlayerComponent,
        VideoPlayerComponent
    ]
})
export class SvCommonModule { }
