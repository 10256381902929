import { Component, Injector, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { SecondPageEditBase } from '../../../classes/base/second-page-edit-base';
import { FsSystemFoldersService } from '../../../services/fssystem-folder.service';

@Component({
    selector: 'app-fssytem-folder-form',
    templateUrl: './fssytem-folder-form.component.html',
    styleUrls: ['./fssytem-folder-form.component.scss']
})
export class FssytemFolderFormComponent extends SecondPageEditBase implements OnInit {
    constructor(
        protected _fsSystemFoldersService: FsSystemFoldersService,
        protected _injector: Injector
    ) {
        super(_fsSystemFoldersService, _injector);

        this.formGroup = new UntypedFormGroup({
            ten: new UntypedFormControl('', Validators.compose([Validators.required]))
        });
    }

    ngOnInit() {
        this.invalid = this.formGroup.invalid;
    }

    async onShowPopup(data: any) {
        this.validationSummary.resetErrorMessages();
        if (data.ten != '') {
            this.popupHeader = 'Sửa tên thư mục ';
            this.itemDetail = data;
            this.itemDetail.id = 1;
            this.itemDetail.idOrganization = data.idOrganization;
        } else {
            this.itemDetail.ten = '';
            this.itemDetail.id = 0;
            this.itemDetail.idOrganization = data.idOrganization;
            this.itemDetail.physicalPathParent = data.physicalPathParent;
        }
    }

    onInsert() {
        this._fsSystemFoldersService.createMyFolder(this.itemDetail)
            .then(response => {
                if (this.continueAdding) {
                    this.resetForm();
                } else {
                    this.closePopupMethod(true);
                }

                this._notifierService.showInsertDataSuccess();
                this.onAfterSave();
                this.submitting = false;
            }, error => {
                this._notifierService.showInsertDataFailed();
                this.submitting = false;
            });
    }

    onUpdate() {
        this._fsSystemFoldersService.updateMyFolder(this.itemDetail)
            .then(response => {
                this.closePopupMethod(true);
                this._notifierService.showUpdateDataSuccess();
                this.submitting = false;
            }, error => {
                this._notifierService.showUpdateDataFailed();
                this.submitting = false;
            });
    }

    resetForm() {
        this.itemDetail.ten = '';
    }
}
