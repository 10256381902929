import { FsItemTrashService } from './../../../services/fs-item-trash.service';
import { Component, Injector, OnInit } from '@angular/core';
import { SecondPageIndexBase } from '../../../classes/base/second-page-index-base';

@Component({
    selector: 'app-fssytem-file-trash',
    templateUrl: './fssytem-file-trash.component.html',
    styleUrls: ['./fssytem-file-trash.component.scss']
})
export class FssytemFileTrashComponent extends SecondPageIndexBase implements OnInit {

    constructor(
        protected _injector: Injector,
        protected _fsItemTrashService: FsItemTrashService,
    ) {
        super(null, _injector);
    }

    ngOnInit() {
        this.cols = [
            { field: 'ten', header: this._translateService.instant('Tên'), visible: true, width: '65%', },
            { field: 'modified', header: this._translateService.instant('TABLEDATA_MODIFIED'), visible: true, width: 'auto', sort: true, dateFormat: 'dd/MM/yyyy HH:mm' },
            { field: 'totalSize', header: this._translateService.instant('Dung lượng'), visible: true, width: 'auto', },

        ];
        this.getData();
    }

    getData() {
        this.resetBulkSelect();
        this._fsItemTrashService.getsByUserId().then(rs => {
            if (rs.success) {
                this.dataSource = rs.data;
            }
        })
    }

    goHome() {
        this.getData();
    }

    restoreItem() {
        this._notifierService.showConfirm('Bạn có chắc chắn muốn khôi phục dữ liệu').then(rs => {
            this._fsItemTrashService.restoreItem(this.selectedItems).then(rs => {
                if (rs.success) {
                    this._notifierService.showUpdateDataSuccess();
                    this.getData();
                }
            })
        });
    }

    deleteItem() {
        this._notifierService.showDeleteConfirm().then(rs => {
            this._fsItemTrashService.deleteItem(this.selectedItems).then(rs => {
                if (rs.success) {
                    this._notifierService.showDeleteDataSuccess();
                    this.getData();
                }
            })
        });
    }

}
