import { FsSystemFileService } from './../../../services/fssystem-file.service';
import { FsSystemFoldersService } from '../../../services/fssystem-folder.service';
import { ExTreeNode, FsItemModel } from './../../../models/fsitem';
import { Component, OnInit, Injector, ViewChild, Input, HostListener } from '@angular/core';
import { SecondPageIndexBase } from '../../../classes/base/second-page-index-base';
import { environment } from '../../../../../../../src/environments/environment';
import { FssytemFolderFormComponent } from '../folder-form/fssytem-folder-form.component';
import { FssystemFileUploadComponent } from '../file-upload/fssystem-file-upload.component';
import { FssystemFileViewerComponent } from '../file-viewer/fssystem-file-viewer.component';
import { FssystemItemSharedComponent } from '../item-shared/fssystem-item-shared.component';
import { FssytemFolderUploadComponent } from '../folder-upload/fssytem-folder-upload.component';
import { OverlayPanel } from 'primeng/overlaypanel';

@Component({
    selector: 'app-fssytem-file-explorer',
    templateUrl: './fssytem-file-explorer.component.html',
    styleUrls: ['./fssytem-file-explorer.component.scss']
})
export class FssytemFileExplorerComponent extends SecondPageIndexBase implements OnInit {
    rootTreeNode: ExTreeNode[] = [
        {
            'label': 'Menu',
            'data': '-1',
            'expandedIcon': 'fa-folder-open',
            'collapsedIcon': 'fa-folder',
            'children': [],
            'type': '1',
        }];
    openTreeSearchAdv: boolean;
    openLeftTableSidebar = true;
    selectedTreeNode: ExTreeNode;
    openLeftSidebar = true;
    breadcrumbItems: any[];
    selectedFsItem: FsItemModel;
    @ViewChild('pEditFolder') pEditFolder: FssytemFolderFormComponent;
    @ViewChild('pUpload') pUpload: FssystemFileUploadComponent;
    @ViewChild('pContext') pContext: OverlayPanel
    @ViewChild('pFileViewer') pFileViewer: FssystemFileViewerComponent;
    @ViewChild('pShared') pShared: FssystemItemSharedComponent;
    @ViewChild('pUploadFolder') pUploadFolder: FssytemFolderUploadComponent;
    root = 0;

    constructor(
        protected _fsSystemFoldersService: FsSystemFoldersService,
        protected _fsSystemFileService: FsSystemFileService,
        protected _injector: Injector,
    ) {
        super(null, _injector);
    }

    ngOnInit() {
        this.cols = [
            { field: 'ten', header: this._translateService.instant('Tên'), visible: true, width: '70%', },
            { field: 'modified', header: this._translateService.instant('TABLEDATA_MODIFIED'), visible: true, width: 'auto', sort: true, dateFormat: 'dd/MM/yyyy HH:mm' },
            { field: 'totalSize', header: this._translateService.instant('Dung lượng'), visible: true, width: 'auto', },

        ];
        this.getData();
        this.loadBreadcrumbItems();
    }

    editFolder() {
        const item = this.selectedItems[0];
        this.pEditFolder.showPopup(item);
    }

    addFolder() {
        var model = {
            ten: '',
            physicalPathParent: this.selectedFsItem?.physicalPath
        };
        this.pEditFolder.showPopup(model);
    }

    async getData() {

        this.dataSource = [];
        this.getTreeFolder();
        this.getRootData();
    }

    getTreeFolder() {
        var model = new FsItemModel();
        model.physicalPath = '';
        // GetTree
        this._fsSystemFoldersService.getTree(model).then(rs => {
            if (rs.success) {
                this.rootTreeNode = rs.data;
                // this.rootTreeNode.forEach(node => {
                //     this.expandRecursive(node, true);
                // });
            }
        });
    }

    getRootData() {
        this.root = 0;
        var model = new FsItemModel();
        model.physicalPath = '';
        this.dataSource = [];
        this._fsSystemFoldersService.getsChildFolder(model).then(rsFo => {
            if (rsFo.success) {
                rsFo.data.map(item =>
                    this.dataSource.push(item)
                );
            }
        });

    }

    async getDataTable() {
        if (this.pContext !== undefined) {
            this.pContext.hide();
        }
        this.dataSource = [];
        if (this.selectedFsItem !== undefined) {
            if (this.selectedFsItem.ten == '...') {
                this.root--;
            } else {
                this.root++;
            }
        }
        if (this.root > 0) {
            this.dataSource.push({
                physicalPath: this.selectedFsItem?.physicalPathParent,
                idFsItemType: 1,
                ten: '...',
            });

            await this._fsSystemFoldersService.getsChildFolder(this.selectedFsItem).then(rsFo => {
                if (rsFo.success) {
                    rsFo.data.map(item =>
                        this.dataSource.push(item)
                    );
                }
            });

            if (this.selectedFsItem.physicalPath != '') {
                await this._fsSystemFileService.getsByFsFolder(this.selectedFsItem).then(rsFi => {
                    if (rsFi.success) {
                        rsFi.data.map(item =>
                            this.dataSource.push(item)
                        );
                    }
                })
            }
        }
        else {
            this.getRootData();
        }

    }

    onDoubleItemClick(item: any) {
        this.selectedFsItem = item;
        if (item.idFsItemType === 1) {
            this.checkNode(this.selectedFsItem.physicalPath);
            this.getDataTable();
        } else {
            // Xem file online (nếu được hỗ trợ)
            if (this._fsSystemFileService.isSupportedViewOnline(this.selectedFsItem.ten)) {
                this.pFileViewer.showPopup(true, this.selectedFsItem);
            } else {
            }
        }
    }

    //#region Tree
    toggleLeftSidebar() {
        this.openLeftSidebar = !this.openLeftSidebar;
    }


    toggleTreeSearchAdv() {
        this.openTreeSearchAdv = !this.openTreeSearchAdv;
    }
    toggleLeftTableSidebar() {
        this.openLeftTableSidebar = !this.openLeftTableSidebar;
    }
    private expandRecursive(node: ExTreeNode, isExpand: boolean) {
        node.expanded = isExpand;
        if (node.children) {
            node.children.forEach(childNode => {
                this.expandRecursive(childNode, isExpand);
            });
        }
    }

    async nodeSelect(event) {
        this.selectedTreeNode = event.node;
        var index = 0;
        let tmpNode = event.node;
        if (tmpNode.parent !== undefined) {
            while (true) {
                if (tmpNode.parent !== undefined) {
                    index++;
                    tmpNode = tmpNode.parent;
                } else {
                    break;
                }
            }
        }
        this.root = index;
        this.selectedFsItem = new FsItemModel();
        this.selectedFsItem.physicalPath = event.node.data;
        this.selectedFsItem.idFsItemType = 1;
        this.selectedFsItem.physicalPathParent = event.node?.parent?.data;
        await this.getDataTable();
    }
    //#endregion



    loadBreadcrumbItems() {
        // this.breadcrumbItems = [];
        // var tempNode = this.selectedTreeNode;
        // console.log(this.selectedTreeNode);

        // while (tempNode !== undefined) {
        //     this.breadcrumbItems.unshift({ label: tempNode.label });
        //     tempNode = tempNode.parent;
        // }
    }

    checkNode(folderId) {
        this.selectedTreeNode = undefined;
        this.rootTreeNode.forEach(element => {
            this.treeFilter(element, folderId);
        });
    }

    treeFilter(node: ExTreeNode, folderId) {
        if (this.selectedTreeNode !== undefined && this.selectedTreeNode.data === folderId) {
            return;
        }
        if (node.data === folderId) {
            this.selectedTreeNode = node;
            return;
        }
        if (node.children) {
            node.children.forEach(childNode => {
                this.treeFilter(childNode, folderId);
            });
        }
    }

    async viewFile() {
        this.pContext.hide();
        if (this.selectedFsItem.idFsItemType === 1) {
            // Mở thư mục con
            this.onDoubleItemClick(this.selectedFsItem);
        } else {
            // Xem file online (nếu được hỗ trợ)
            if (this._fsSystemFileService.isSupportedViewOnline(this.selectedFsItem.ten)) {

                this.pFileViewer.showPopup(true, this.selectedFsItem);
            } else {
                // download file
                top.location.href = await this._fsSystemFileService.getAnonymousDownloadUrl(this.selectedFsItem.webPath);
            }
        }
    }

    copyUrl() {
        this.pContext.hide();
        if (this.selectedFsItem.idFsItemType > 1) {
            document.addEventListener('copy', (e: ClipboardEvent) => {
                e.clipboardData.setData('text/plain', (`${environment.apiDomain.svFsSystemEndpoint}${this.selectedFsItem.webPath}`));
                e.preventDefault();
                document.removeEventListener('copy', null);
            });
            document.execCommand('copy');
            this._notifierService.showSuccess('Đã copy link vào clipboard');
        }

    }

    goHome() {
        this.selectedFsItem = undefined;
        this.getData();
    }
    uploadFiles() {
        this.pUpload.showPopup(this.selectedFsItem);
    }

    async onClickFsItem(item: any) {
        await this.dataSource.forEach(element => {
            element.isClick = false;
        });
        item.isClick = true;
    }

    showContectMenu(event: any, item: any) {
        this.selectedFsItem = item;
        this.pContext.toggle(event);
    }

    @HostListener('contextmenu', ['$event'])
    onRightClick(event) {
        event.preventDefault();
    }

    deleteFsItem() {
        this.pContext.hide();
        this._notifierService.showDeleteConfirm().then(rs => {
            if (this.selectedFsItem.idFsItemType > 1) {
                this._fsSystemFileService.deleteFile(this.selectedFsItem).then(rs => {
                    if (rs.success) {
                        this._notifierService.showDeleteDataSuccess();
                        this.onPopupClosed1(true);
                    }
                });
            } else {
                this._fsSystemFoldersService.deleteFolder(this.selectedFsItem).then(rs => {
                    if (rs.success) {
                        this._notifierService.showDeleteDataSuccess();
                        this.onPopupClosed1(true);
                    }
                });
            }
        });
    }
    unzipItem() {
        this.pContext.hide();
        if (this.selectedFsItem.idFsItemType == 17) {
            this._fsSystemFileService.unZipFile(this.selectedFsItem).then(rs => {
                if (rs.success) {
                    this._notifierService.showUpdateDataSuccess();
                }
            })
        } else {
            this._notifierService.showWarning('Chỉ giải nén được các file .zip')
        }
    }

    shareItem() {
        this.pContext.hide();
        this.pShared.showPopup(this.selectedFsItem);
    }

    uploadFolder() {
        this.pUploadFolder.showPopup(this.selectedFsItem);
    }

    editFsItem() {
        this.pContext.hide();
        this.pEditFolder.showPopup(this.selectedFsItem);
    }

    async onPopupClosed1(data: any) {
        if (data) {
            if (this.selectedTreeNode !== undefined) {
                this.selectedFsItem = new FsItemModel();
                this.selectedFsItem.physicalPath = this.selectedTreeNode.data;
                this.selectedFsItem.idFsItemType = 1;
                this.selectedFsItem.physicalPathParent = this.selectedTreeNode?.parent?.data;
                this.getDataTable();
            } else {
                this.getData();
            }
        }
        this.getTreeFolder();
    }

    mp4Encode() {
        this.pContext.hide();
        if (this.selectedFsItem.idFsItemType == 21) {
            this._fsSystemFileService.encodeMp4(this.selectedFsItem).then(rs => {
                if (rs.success) {
                    this._notifierService.showUpdateDataSuccess();
                    this.onPopupClosed1(true);
                }
            })
        } else {
            this._notifierService.showWarning('Chỉ thực hiện được với file .mp4')
        }
    }
}

