import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { PermissionService } from './permission.service';
import { NotifierService } from './notifier.service';
import { AuthenticationService } from './authentication.service';
import { OAuthService } from 'angular-oauth2-oidc';


@Injectable({
    providedIn: 'root'
})
export class GuardIsTeacherService implements CanActivate {

    readonly storage = sessionStorage;

    constructor(
        private _authenticationService: AuthenticationService,
        private _permissionService: PermissionService,
        private _router: Router,
        private _oauthService: OAuthService,
    ) {

    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
        return new Promise(async (resolve) => {
            console.log(this._oauthService.hasValidAccessToken());
            if (this._oauthService.hasValidAccessToken()) {
                const currUser = await this._authenticationService.getCurrentUser();
                if (currUser.typeId == 1) {
                    resolve(true);
                } else {
                    resolve(false);
                }
            } else {
                resolve(false);
            }
        });
    }
}
