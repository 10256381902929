import { Pipe, PipeTransform } from '@angular/core';
import { environment } from '../../../../../src/environments/environment';

@Pipe({
    name: 'thumbor'
})
export class ThumborPipe implements PipeTransform {

    transform(imageUrl: string, args: string): any {
        if (environment.appMetadata.main.useThumbor) {
            return `${environment.appMetadata.main.thumborUrl}/unsafe/${args}/${imageUrl.replace(/^https?:\/\//, '')}`
        } else {
            return imageUrl;
        }
    }
}
