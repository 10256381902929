export enum PermissionTypes {
    PAGE    = 1,
    CONTROL = 2
}


export enum NotificationObjectType {
    CATEGORY_UPDATED = '-1'  // Cập nhật mới danh mục
}


export enum TemplateType {
    EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8',
    DOC_TYPE   = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document;charset=UTF-8'
}

export enum Extension {
    EXCEL_EXTENSION = '.xlsx',
    DOC_EXTENSION   = '.docx'
}

export enum Pattern {
    datetime = '^([0-2][0-9]|(3)[0-1])(\/)(((0)[0-9])|((1)[0-2]))(\/)\d{4}$'
}
