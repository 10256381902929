<div class="sv-page-content-area">
    <!-- <div class="sv-page-content-header-area">
        <div class="__page-title-area row">
            <div class="ui-g-12 ui-lg-6 __left-area">
                <h1 class="sv-page-title">
                    {{'Chia sẻ với tôi' | translate}}
                    <span class="__helper ci ci-help"></span>
                </h1>
            </div>
            <div class="ui-g-12 ui-lg-6 __right-area">
            </div>
        </div>
    </div> -->
    <div class="sv-page-content-area__body">
        <div class="sv-data-view-area">
            <div class="sv-data-view-area__main-block">
                <div class="sv-data-view-main-action-area">
                    <div class="sv-data-view-main-action-area" style="flex: 1 1 400px">
                        <button type="button" (click)="goHome()" id="act-refresh"
                            class="__data-tree-tool-toggle sv-btn -icon-only sv-bgc--gray-10 sv-bgc--gray-20--hover sv-bgc--gray-30--active sv-bgc--gray-30--activated h-easing--flash sv-icon-color--primary"
                            pTooltip="{{'Thư mục gốc' | translate }}">
                            <span class="__icon ci ci-home"></span>
                        </button>

                    </div>
                    <div class="sv-data-view-main-action-area__right-block">
                        <!-- <div class="h-mb-8">{{totalRecord}}</div> -->
                    </div>
                </div>
                <div class="sv-table-wrapper">
                    <div class="file-grid-wrapper">
                        <div class="file-list grid">
                            <div class="file-wrapper file-item" *ngFor="let item of dataSource">
                                <div class="file-item" (contextmenu)="showContectMenu($event, item)"
                                    (click)="onClickFsItem(item)" [class.is-clicked]="item.isClick"
                                    (dblclick)="onDoubleItemClick(item)">
                                    <div class="icon-item"
                                        [innerHTML]="item.ten | icon : item.idFsItemType == 1 : item.webPath">
                                    </div>
                                    <div class="item-name">
                                        <b class="name">
                                            {{item.ten}}
                                        </b>
                                        <div class="item-info">
                                            <span class="item-length" *ngIf="item.idFsItemType > 1">
                                                {{item.totalSize | fileSize}}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <p-overlayPanel #pContext [hideTransitionOptions]="'0ms'" [showTransitionOptions]="'0ms'"
                        appendTo="body" styleClass="contextmenu">
                        <div class="menu-options">
                            <ul class="menu-option-group">
                                <li class="menu-option" (click)="viewFile()">
                                    <div class="icon"><svg xmlns="http://www.w3.org/2000/svg" width="17px" height="17px"
                                            viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
                                            stroke-linecap="round" stroke-linejoin="round" class="feather feather-eye">
                                            <path d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z"></path>
                                            <circle cx="12" cy="12" r="3"></circle>
                                        </svg></div>
                                    <div class="text-label">
                                        Xem
                                    </div>
                                </li>
                                <li class="menu-option" (click)="copyUrl()"
                                    [hidden]="selectedFsItem?.idFsItemType <= 1">
                                    <div class="icon"><svg xmlns="http://www.w3.org/2000/svg" width="17px" height="17px"
                                            viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
                                            stroke-linecap="round" stroke-linejoin="round" class="feather feather-eye">
                                            <path d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z"></path>
                                            <circle cx="12" cy="12" r="3"></circle>
                                        </svg></div>
                                    <div class="text-label">
                                        Copy Link
                                    </div>
                                </li>
                                <!---->
                            </ul>
                        </div>
                    </p-overlayPanel>
                </div>
            </div>
        </div>
    </div>
</div>

<app-fssystem-file-viewer #pFileViewer></app-fssystem-file-viewer>
