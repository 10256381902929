import { FssystemFileExplorerBygroupModalComponent } from './../modal-file-explorer-bygroup/fssystem-file-explorer-bygroup-modal.component';
import { FssystemSharedWithMeModalComponent } from './../modal-shared-with-me/fssystem-shared-with-me-modal.component';
import { FssytemFileExplorerModalComponent } from './../modal-file-explorer/fssytem-file-explorer-modal.component';
import { SecondPageEditBase } from './../../../classes/base/second-page-edit-base';
import { Component, EventEmitter, Injector, OnInit, Output, ViewChild } from '@angular/core';
import { SecondPageIndexBase } from './../../../classes/base/second-page-index-base';


@Component({
    selector: 'app-fssystem-modal',
    templateUrl: './fssystem-modal.component.html',
    styleUrls: ['./fssystem-modal.component.scss']
})
export class FssystemModalComponent extends SecondPageIndexBase implements OnInit {

    @Output() closePopup = new EventEmitter<any>();
    @ViewChild('pModal') pEdit: FssytemFileExplorerModalComponent;
    @ViewChild('pModalByGroup') pModalByGroup: FssystemFileExplorerBygroupModalComponent;
    @ViewChild('pModalShareWithMe') pModalShareWithMe: FssystemSharedWithMeModalComponent;
    isShow = false;
    constructor(
        protected _injector: Injector,
    ) {
        super(null, _injector);
    }

    ngOnInit() {
    }

    onPopupClosed(data) {
        console.log(data);
        this.closePopup.next(data);
        this.isShow = false;
    }

    onShowPopup(data: any) {
        this.isShow = true;
    }

    getMaxDialogHeight() {
        return (window.innerHeight - 200).toString() + 'px';
    }

}
