import { Injectable } from '@angular/core';
import { MessageService, ConfirmationService, Message } from 'primeng/api';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
    providedIn: 'root'
})
export class NotifierService {

    constructor(
        private _messageService: MessageService,
        private _confirmService: ConfirmationService,
        private _translateService: TranslateService
    ) {

    }

    showSuccess(message: string, header: string = '', life: number = 10000) {
        this._messageService.add({
            key: 'success-warn',
            severity: 'success',
            summary: header,
            detail: message,
            life: life
        });
    }

    showInsertDataSuccess(life: number = 10000) {
        this._messageService.add({
            key: 'success-warn',
            severity: 'success',
            summary: this._translateService.instant('MESSAGE_INSERT_DATA_SUCCESS'),
            detail: this._translateService.instant('MESSAGE_COMMON_SUCCESS_HEADER'),
            life: life
        });
    }

    showUpdateDataSuccess(life: number = 10000) {
        this._messageService.add({
            key: 'success-warn',
            severity: 'success',
            summary: this._translateService.instant('MESSAGE_UPDATE_DATA_SUCCESS'),
            detail: this._translateService.instant('MESSAGE_COMMON_SUCCESS_HEADER'),
            life: life
        });
    }

    showDeleteDataSuccess(life: number = 5000) {
        this._messageService.add({
            key: 'success-warn',
            severity: 'success',
            summary: this._translateService.instant('MESSAGE_DELETE_DATA_SUCCESS'),
            detail: this._translateService.instant('MESSAGE_COMMON_SUCCESS_HEADER'),
            life: life
        });
    }

    showDeleteDataError(life: number = 5000) {
        this._messageService.add({
            key: 'error',
            severity: 'error',
            summary: this._translateService.instant('MESSAGE_DELETE_DATA_ERROR'),
            detail: this._translateService.instant('MESSAGE_COMMON_ERROR_HEADER'),
            life: life
        });
    }

    showDeleteDataEmptyError(life: number = 5000) {
        this._messageService.add({
            key: 'error',
            severity: 'error',
            summary: this._translateService.instant('Bạn phải chọn ít nhất 1 bản ghi để xóa'),
            detail: this._translateService.instant('MESSAGE_COMMON_ERROR_HEADER'),
            life: life
        });
    }

    showWarning(message: string, header: string = '', life: number = 10000) {
        this._messageService.add({
            key: 'success-warn',
            severity: 'warn',
            summary: header,
            detail: message,
            life: life
        });
    }

    showError(message: string, header: string = '', life: number = 10000) {
        this._messageService.add({
            key: 'error',
            severity: 'error',
            summary: header,
            detail: message,
            life: life
        });
    }

    showHttpUnknowError(life: number = 10000) {
        this._messageService.add({
            key: 'error',
            severity: 'error',
            summary: this._translateService.instant('MESSAGE_HTTP_UNKNOW_ERROR_HEADER'),
            detail: this._translateService.instant('MESSAGE_HTTP_UNKNOW_ERROR'),
            life: life
        });
    }

    showResponseError(status: number, life: number = 10000, serviceName: string = '') {
        if (status === 404) {
            this._messageService.add({
                key: 'error',
                severity: 'error',
                summary: this._translateService.instant('MESSAGE_COMMON_ERROR_HEADER'),
                detail: this._translateService.instant('MESSAGE_NOT_FOUND_ERROR'),
                life: life
            });
        } else if (status === 401) {
            this._messageService.add({
                key: 'error',
                severity: 'error',
                summary: this._translateService.instant('MESSAGE_COMMON_ERROR_HEADER'),
                detail: this._translateService.instant('MESSAGE_UNAUTHORIZED_ERROR'),
                life: life
            });
        } else if (status === 403) {
            this._messageService.add({
                key: 'error',
                severity: 'error',
                summary: this._translateService.instant('MESSAGE_COMMON_ERROR_HEADER'),
                detail: this._translateService.instant('MESSAGE_ACCESS_DENINED_ERROR'),
                life: life
            });
        } else if (status === 500) {
            this._messageService.add({
                key: 'error',
                severity: 'error',
                summary: this._translateService.instant('MESSAGE_COMMON_ERROR_HEADER'),
                detail: this._translateService.instant('MESSAGE_INTERNAL_SERVER_ERROR'),
                life: life
            });
        } else if (status === 409) {
            this._messageService.add({
                key: 'error',
                severity: 'error',
                summary: this._translateService.instant('MESSAGE_COMMON_ERROR_HEADER'),
                detail: this._translateService.instant('MESSAGE_CONFLICT_ERROR'),
                life: life
            });
        } else if (status === 400) {
            this._messageService.add({
                key: 'error',
                severity: 'error',
                summary: this._translateService.instant('Dữ liệu đầu vào không hợp lệ'),
                detail: this._translateService.instant('Vui lòng kiểm tra dữ liệu đầu vào'),
                life: life
            });
        } else if (status === 0) {
            this._messageService.add({
                key: 'error',
                severity: 'error',
                summary: this._translateService.instant(`Dịch vụ ${serviceName} không hoạt động`),
                detail: this._translateService.instant('Vui lòng kiểm tra lại cài đặt hệ thống'),
                life: life
            });
        }
    }

    showInsertDataFailed(life: number = 10000) {
        this._messageService.add({
            key: 'error',
            severity: 'error',
            summary: this._translateService.instant('MESSAGE_INSERT_DATA_FAILED'),
            detail: this._translateService.instant('MESSAGE_COMMON_ERROR'),
            life: life
        });
    }

    showUpdateDataFailed(life: number = 10000) {
        this._messageService.add({
            key: 'error',
            severity: 'error',
            summary: this._translateService.instant('MESSAGE_UPDATE_DATA_FAILED'),
            detail: this._translateService.instant('MESSAGE_COMMON_ERROR'),
            life: life
        });
    }
    showUpdateDataSingleFailed(life: number = 10000) {
        this._messageService.add({
            key: 'error',
            severity: 'error',
            summary: this._translateService.instant('MESSAGE_UPDATE_DATA_FAILED'),
            detail: this._translateService.instant('Mã hoặc tên danh mục đã tồn tại'),
            life: life
        });
    }
    showUpdateDataConfigurationFailed(life: number = 10000) {
        this._messageService.add({
            key: 'error',
            severity: 'error',
            summary: this._translateService.instant('Mật khẩu không được để trống'),

            life: life
        });
    }
    showConfirm(message: string, header: string = '', icon: string = ''): Promise<any> {
        return new Promise((resolve, reject) => {
            this._confirmService.confirm({
                message: message,
                header: header,
                icon: icon,
                acceptLabel: this._translateService.instant('CONFIRM_ACCEPT_LABEL'),
                rejectLabel: this._translateService.instant('CONFIRM_REJECT_LABEL'),
                accept: () => {
                    resolve(true);
                },
                reject: () => {
                    reject();
                }
            });
        });
    }

    showDeleteConfirm(header: string = '', icon: string = ''): Promise<any> {
        return new Promise((resolve, reject) => {
            this._confirmService.confirm({
                message: this._translateService.instant('CONFIRM_DEFAULT_DELETE_CONFIRM'),
                header: this._translateService.instant('CONFIRM_DEFAULT_DELETE_CONFIRM_TITLE'),
                icon: icon,
                acceptLabel: this._translateService.instant('CONFIRM_ACCEPT_LABEL'),
                rejectLabel: this._translateService.instant('CONFIRM_REJECT_LABEL'),
                accept: () => {
                    resolve(true);
                },
                reject: () => {
                    reject();
                }
            });
        });
    }

    addErrorMessage(msgs: Message[], content) {
        msgs.push({ key: 'error', severity: 'error', summary: 'Error Message', detail: 'Validation failed' });
    }

    clearErrorMessage(msgs: Message[]) {
        msgs.length = 0;
    }
}
