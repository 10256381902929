import { FsSystemFileService } from './../../../services/fssystem-file.service';
import { Component, OnInit, Injector, ViewChild } from '@angular/core';
import { FileUpload } from 'primeng/fileupload';
import { SecondPageEditBase } from '../../../classes/base/second-page-edit-base';

@Component({
    selector: 'app-fssystem-file-upload',
    templateUrl: './fssystem-file-upload.component.html',
    styleUrls: ['./fssystem-file-upload.component.scss']
})
export class FssystemFileUploadComponent extends SecondPageEditBase implements OnInit {

    progressValue = 0;
    apiUploadUrl = '';
    accept = 'image/*,.doc,.docx,.xls,xlsm,.xlsx,ppt,pptm,pptx,.pdf,.rar,.zip,docm,dot,dotm,dotx,epub,htm,html,mht,odt,ott,rtf,txt,djvu,xps,csv,fods,ods,ots,xlt,xltm,xltx,fodp,odp,otp,pot,potm,potx,pps,ppsm,ppsx';
    maxFileSize = 1000000000;
    physicalPath = '';
    uploadedFiles: any[] = [];
    @ViewChild('fileControl') fileControl: FileUpload;
    constructor(
        protected _fsSystemFileService: FsSystemFileService,
        protected _injector: Injector
    ) {
        super(_fsSystemFileService, _injector);
    }

    ngOnInit() {
        this.apiUploadUrl = this._fsSystemFileService.getUploadUrl();
    }

    onShowPopup(data: any) {
        this.physicalPath = data.physicalPath;
    }

    onBeforeUpload(evt) {
        evt.formData.append('ownedId', this.currentUser.userId);
        evt.formData.append('physicalPath', this.physicalPath);
    }

    onSelectFile() {
        this.fileControl.upload();
    }

    onUploadProgress(evt) {
        this.progressValue = evt.progress;
    }

    onUpload(event) {
        for (let file of event.files) {
            this.uploadedFiles.push(file);
        }
    }

    onUploaded(evt) {
        this.closePopupMethod(true);
    }

}
