<header class="elh-topbar">
    <div class="container">
        <div class="row">
            <div class="col-sm-5 hidden-xs col-8">
                <div class="top-block">
                    <p class="top-phone"><i class="fa fa-phone"></i>
                        <a href="tel:+84 24 7305 8668">+84 24 7305 8668</a>
                    </p>
                    <p class="top-email"><i class="fa fa-envelope-o"></i>
                        <a href="mailto:  tuyensinh@edisonschools.edu.vn"> tuyensinh@edisonschools.edu.vn</a>
                    </p>
                </div>
            </div>
            <div class="col-sm-7" style="flex: 5 1;
            display: flex;
            align-items: center;
            justify-content: flex-end;" (clickOutside)="closeRightSidebar($event)">
                <div class="top-block top-links">
                    <a *ngIf="!isLogin" routerLink="/signin">Đăng nhập</a>
                    <a routerLink="/teacher" class="__topbar-action-btn" *ngIf="currentUser.typeId == 1">Giảng dạy &
                        đánh
                        giá</a>
                    <a routerLink="/study" class="__topbar-action-btn" *ngIf="currentUser.typeId == 2">Các lớp đang
                        học</a>
                    <a routerLink="/study/mytask" class="__topbar-action-btn" *ngIf="currentUser.typeId == 2">Nhiệm vụ
                        học
                        tập</a>
                    <a *ngIf="isLogin" (click)="showDropdownMenu = !showDropdownMenu">{{currentUser.displayName}}</a>
                    <ul class="dropdown-menu" [style.display]="showDropdownMenu ? 'block': 'none'">
                        <li><a routerLink="/admin" *ngIf="currentUser.typeId == 1">
                                <i class="fa fa-gear"></i> &nbsp;Quản trị</a>
                        </li>
                        <li><a routerLink="/profile">
                                <i class="fa fa-user-o"></i> &nbsp;Thông tin cá nhân</a>
                        </li>
                        <li *ngIf="currentUser.typeId == 1"><a routerLink="/interactive-video">
                                <i class="fa fa-video-camera"></i> &nbsp;Danh sách bài giảng tương tác</a>
                        </li>
                        <li *ngIf="currentUser.typeId == 1"><a routerLink="/teacher/storage/mystorage">
                                <i class="fa fa-file-o"></i> &nbsp;Kho dữ liệu số hóa</a>
                        </li>
                        <li *ngIf="currentUser.typeId == 1"><a routerLink="/teacher/course/old">
                                <i class="fa fa-list"></i> &nbsp;Các lớp học đã kết thúc</a>
                        </li>
                        <!--
                        <li *ngIf="currentUser.typeId == 2"><a href="/study">Các lớp đang học</a></li>
                        <li *ngIf="currentUser.typeId == 2"><a href="/study/mytask">Nhiệm vụ học tập</a></li> -->
                        <li><a (click)="doLogOut()">
                                <i class="fa fa-external-link"></i> &nbsp;Đăng xuất</a>
                        </li>
                    </ul>
                    <a *ngIf="isLogin" class="__topbar-action-btn" (click)="toggleRightSidebar()">
                        <i class="__icon ci ci-notification-new"></i>
                        <span class="topbar-badge animated rubberBand">{{totalUnRead}}</span>
                    </a>
                    <ng-container *ngTemplateOutlet="rightSideBar"></ng-container>
                </div>
            </div>
        </div>
    </div>
</header>
<nav class="navbar">
    <div class="container">
        <!-- Brand and toggle get grouped for better mobile display -->
        <div class="navbar-header">
            <button type="button" class="navbar-toggle collapsed" (click)="isShowMenu = !isShowMenu"
                aria-expanded="false">
                <span class="sr-only">Toggle navigation</span>
                <span class="icon-bar"></span>
                <span class="icon-bar"></span>
                <span class="icon-bar"></span>
            </button>
            <a class="navbar-brand" routerLink="/"><img src="assets/images/main/logo.png" alt="Site Logo"></a>
        </div>

        <div class="collapse navbar-collapse" [ngClass]="{'in' : isShowMenu}">
            <div class="navbar-search navbar-right" (clickOutside)="closeSearchForm($event)">
                <button id="navbar-search-toggle" class="navbar-search-toggle" (click)="showBoxSearch = !showBoxSearch">
                    <i class="fa fa-search"></i>
                </button>
                <form id="navbar-searchform" class="navbar-searchform" *ngIf="showBoxSearch">
                    <input type="search" name="search" [(ngModel)]="query" (keyup.enter)="onSearch()"
                        placeholder="Nhập từ khóa...">
                    <button type="submit" (click)="onGoSearch()"><i class="fa fa-search"></i></button>
                </form>
            </div>
            <ul class="nav navbar-nav navbar-right">
                <li><a routerLink="/">Trang chủ</a></li>
                <li><a routerLink="/news">Thông báo</a></li>
                <li><a routerLink="/course">Lớp môn học</a></li>
                <!-- <li *ngIf="isLogin"><a href="/ilib">Thư viện</a></li> -->
            </ul>
        </div>
    </div>
</nav>

<ng-template #rightSideBar>
    <div class="sv-right-sidebar-area" [ngClass]="{
                    'show': showDropdown.rightSidebar,
                    'hide': !showDropdown.rightSidebar
                    }" *ngIf="notifications.length > 0">
        <div class="sv-right-sidebar-tabs-container">
            <div class="sv-right-sidebar-tab-item -activated" (click)="readAll($event)">
                {{'Đọc tất cả' | translate}}
            </div>

            <!-- <div class="__tab-item" [class.activated]="currentSidebarTab === SidebarTabs.ACTIVITY"
                (click)="currentSidebarTab = SidebarTabs.ACTIVITY">
                {{'USERINFO_TAB_ACTIVITY' | translate}}
            </div> -->
        </div>

        <div class="sv-right-sidebar-panel-container">
            <div class="sv-notification-container" [hidden]="currentSidebarTab !== SidebarTabs.NOTIFICATION">
                <!-- <app-notification></app-notification> -->
                <div class="sv-notification-container">
                    <ng-template ngFor let-item [ngForOf]="notifications">
                        <div class="sv-notification-item -unseen" [class.unseen]="!item.isRead">
                            <div class="sv-notification-item__icon ci" [ngClass]="{
                                        'ci-chat': item.type === 1,
                                        'ci-bookmark': item.type === 2,
                                        'ci-edit': item.type === 3,
                                        'ci-notification': item.type === 4 || item.type === null || item.type === undefined
                                    }" (click)="readItem(item)"></div>

                            <div class="sv-notification-item__title" (click)="goItemLink(item)">{{item.tieuDe}}</div>

                            <div class="sv-notification-item__time">{{item.created | date:'dd/MM/yyyy HH:mm'}}</div>
                        </div>
                    </ng-template>
                </div>
            </div>

            <!-- <div class="__activity-panel" [hidden]="currentSidebarTab !== SidebarTabs.ACTIVITY">
                <div class="py-8 px-16">
                    {{'USERINFO_LIST_ACTIVITY' | translate}}
                </div>
            </div> -->
        </div>
    </div>
</ng-template>