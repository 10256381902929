import { Component, OnInit, ViewChild } from '@angular/core';
import { SvUser, UserService, AuthenticationService, SignalRService, NotificationService, NotifierService } from 'sv-shared';
import { OAuthService } from 'angular-oauth2-oidc';
import { Router } from '@angular/router';
import { environment } from '../../../environments/environment';
import { query } from '@angular/animations';

enum SidebarTabs {
    NOTIFICATION,
    ACTIVITY
}

@Component({
    selector: 'app-menu',
    templateUrl: './app-menu.component.html',
    styleUrls: ['./app-menu.component.css']
})
export class AppMenuComponent implements OnInit {
    currentUser = new SvUser;
    isLogin = false;
    isShowMenu = false;
    showDropdownMenu = false;

    showDropdown = {
        subsystem: false,
        userSetting: false,
        rightSidebar: false
    };
    SidebarTabs = SidebarTabs;
    currentSidebarTab: SidebarTabs = SidebarTabs.NOTIFICATION;

    maxNotification = 10;
    totalUnRead = 0;
    url = '';
    urldetail = '';
    notifications = [];
    userId: string;
    currentItem: any;
    setupSignalr = false;
    showBoxSearch = false;
    query = '';
    constructor(
        protected _authenticationService: AuthenticationService,
        private _oauthService: OAuthService,
        private auth: AuthenticationService,
        private _router: Router,
        private _userService: UserService,
        private _signalRService: SignalRService,
        private _notificationService: NotificationService,
        protected _notifierService: NotifierService,
    ) {
        if (this._oauthService.hasValidAccessToken()) {
            this._signalRService.initNotificationHub();
        }
    }

    ngOnInit() {
        if (!this._oauthService.hasValidAccessToken()) {
            this.isLogin = false;
        } else {
            this.isLogin = true;
            this.currentUser = this._authenticationService.getCurrentUser();
            this._userService.getId().then(rs => {
                this.userId = rs.toString();
                if (!this.setupSignalr) {
                    this._signalRService.start(
                        environment.signalrConfig.topic.notificationCreated,
                        'NotificationHub',
                        this.notifyTrigger.bind(this)
                    );
                    this.refreshNotification();
                    this.setupSignalr = true;
                }
            });
        }
    }

    doLogOut() {
        this.auth.logout();
        top.location.href = "/";
    }

    // Sidebar
    //-------------------

    closeRightSidebar(event) {
        this.showDropdownMenu = false;
    }

    toggleRightSidebar() {
        this.showDropdown.rightSidebar = !this.showDropdown.rightSidebar;
    }


    //Notification
    notifyTrigger(data: any) {
        // this.notifications.unshift(data);

        // if (this.notifications.length > this.maxNotification) {
        //     this.notifications.pop();
        // }
        // this.totalUnRead++;
        console.log(data);
        if (data !== null) {
            if (data.userId !== null) {
                if (data.userId.indexOf(',' + this.userId + ',') !== -1) {
                    console.log('refreshNotification:  ' + this.userId);
                    this.refreshNotification();
                    this._notifierService.showSuccess("Có thông báo mới");
                } else {
                    sessionStorage.setItem('key1', data.msg);
                    this.currentUser.key1 = data.msg;
                    this._authenticationService.setCurrentUser(this.currentUser);
                }
            } else {
                console.log('refreshNotification:  All');
                this.refreshNotification();
            }
        }
    }

    refreshNotification() {
        this._notificationService.getsToUserId(Number(this.userId), 'false', 1, 9999)
            .then(result => {
                this.notifications = result.data;
                this.totalUnRead = result.totalRecord;
            });
    }

    readAll(event) {
        event.preventDefault();
        event.stopPropagation();

        this._notificationService.readAll().then(rs => {
            this.notifications.forEach(element => {
                if (element.read != null) {
                    element.read.push({ userId: this.userId });
                } else {
                    element.read = [{ userId: this.userId }];
                }
            });
        });

        this.totalUnRead = 0;
    }

    readItem(item) {
        item.isRead = true;
        this._notificationService.readMessage(item.toId).then(rs => {
        });
    }

    goItemLink(item: any) {
        if (item.link) {
            this._router.navigate([`${item.link}`]);
            this.readItem(item);
        }
    }

    onGoSearch() {
        var url = `/course?act=search`;
        this.showBoxSearch = false;
        if (this.query !== undefined && this.query !== '') {
            url += `&query=${this.query}`
        }
        this._router.navigateByUrl(url);
    }

    closeSearchForm(event) {
        this.showBoxSearch = false;
    }

}
