<footer>
    <div class="elh-footer-widget-area">
        <div class="container">
            <div class="elh-footer-widgets">
                <div class="row">
                    <div class="col-md-12 col-xs-12">
                        <div class="col-md-4 col-sm-12">
                            <aside class="widget">
                                <a class="footer-logo" routerLink="/">
                                    <img src="assets/images/main/logo-2.png" alt="Site Logo">
                                </a>
                                <div class="row">
                                    <div class="mt-4">
                                        <h4>TRƯỜNG PTLC EDISON</h4>
                                    </div>
                                </div>
                                <div class="row mt-4">
                                    <ul>
                                        <li>
                                            <i class="fa fa-envelope"></i>
                                            <a
                                                href="mailto:tuyensinh@edisonschools.edu.vn">tuyensinh@edisonschools.edu.vn</a>
                                        </li>
                                        <li>
                                            <i class="fa fa-globe"></i>
                                            <a href="https://edisonschools.edu.vn/">https://edisonschools.edu.vn/</a>
                                        </li>
                                    </ul>

                                </div>
                            </aside>
                        </div>
                        <div class="col-md-4 col-sm-12">
                            <aside class="widget">
                                <div class="row">
                                    <h4>CƠ SỞ 1: EDISON SCHOOLS ECOPARK</h4>
                                    <ul class="mt-4">
                                        <li>
                                            <i class="fa fa-map-marker"></i> Phân khu Aquabay, Khu đô thị Ecopark
                                        </li>
                                        <li>
                                            <i class="fa fa-phone"></i> 096 880 8386
                                        </li>
                                        <li>
                                            <i class="fa fa-envelope"></i>
                                            <a
                                                href="mailto:tuyensinh@edisonschools.edu.vn">tuyensinh@edisonschools.edu.vn</a>
                                        </li>
                                    </ul>
                                </div>
                                <div class="row  mt-4">
                                    <h4>KẾT NỐI VỚI EDISON SCHOOLS ECOPARK</h4>
                                    <ul class="social_link">
                                        <li><a class="facebook hasTooltip"
                                                href="https://www.facebook.com/EdisonSchoolsEcopark/" target="_blank"><i
                                                    class="fa fa-facebook"></i></a></li>
                                        <li><a class="youtube hasTooltip"
                                                href="https://www.youtube.com/channel/UCEcROo-oprsMTdMejqPKMHA/featured"
                                                target="_blank"><i class="fa fa-youtube"></i></a></li>
                                        <li><a class="snapchat hasTooltip" href="https://m.me/EdisonSchoolsEcopark/"
                                                target="_blank"><i class="fa fa-snapchat"></i></a></li>
                                    </ul>
                                </div>
                            </aside>
                        </div>
                        <div class="col-md-4 col-sm-12">
                            <aside class="widget">
                                <div class="row">
                                    <h4>CƠ SỞ 2: EDISON SCHOOLS AN KHÁNH</h4>
                                    <ul class="mt-4">
                                        <li>
                                            <i class="fa fa-map-marker"></i> Khu đô thị Mailand Hanoi City, Bắc An
                                            Khánh, Hoài Đức, Hà Nội
                                        </li>
                                        <li>
                                            <i class="fa fa-phone"></i> 098 996 1616
                                        </li>
                                        <li>
                                            <i class="fa fa-envelope"></i> <a
                                                href="mailto:tuyensinhankhanh@edisonschools.edu.vn">tuyensinhankhanh@edisonschools.edu.vn</a>
                                        </li>
                                    </ul>
                                </div>
                                <div class="row mt-4">
                                    <h4>KẾT NỐI VỚI EDISON SCHOOLS AN KHÁNH</h4>
                                    <ul class="social_link">
                                        <li><a class="facebook hasTooltip"
                                                href="https://www.facebook.com/EdisonSchoolsAnKhanh/" target="_blank"><i
                                                    class="fa fa-facebook"></i></a></li>
                                        <li><a class="youtube hasTooltip"
                                                href="https://www.youtube.com/channel/UCEcROo-oprsMTdMejqPKMHA/featured"
                                                target="_blank"><i class="fa fa-youtube"></i></a></li>
                                        <li><a class="snapchat hasTooltip" href="https://m.me/EdisonSchoolsAnKhanh/"
                                                target="_blank"><i class="fa fa-snapchat"></i></a></li>
                                    </ul>
                                </div>
                            </aside>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="elh-footer-lower-area">
        <div class="container">
            <div class="row">
                <div class="col-sm-6 col-xs-12">
                    <ul class="footer-menu footer-menu-left socials">
                        <li>
                            <a target="_blank" href="https://edisonschools.edu.vn/su-menh-tam-nhin-muc-tieu/">Giới
                                thiệu</a>
                        </li>
                        <li>
                            <a href="https://edisonschools.edu.vn/contact/">Liên hệ</a>
                        </li>
                    </ul>
                </div>
                <div class="col-sm-6 col-xs-12 text-right xs-text-center">
                    <div class="elh-footer-widget-area no-padding">
                        <div class="widget">
                            <ul class="footer-menu socials">
                                <li>© Edison 2020 all right reserved. </li>
                                <li>
                                    <a href="https://www.facebook.com/EdisonSchoolsEcopark/" target="_blank"><i
                                            class="fa fa-facebook"></i></a>
                                </li>
                                <li>
                                    <a href="https://www.youtube.com/channel/UCEcROo-oprsMTdMejqPKMHA/featured"
                                        target="_blank"><i class="fa fa-youtube"></i></a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</footer>
