<div class="sv-page-content-area">
    <!-- <div class="sv-page-content-header-area">
        <div class="__page-title-area row">
            <div class="ui-g-12 ui-lg-6 __left-area">
                <h1 class="sv-page-title">
                    {{'Thùng rác' | translate}}
                    <span class="__helper ci ci-help"></span>
                </h1>
            </div>
            <div class="ui-g-12 ui-lg-6 __right-area">
            </div>
        </div>
    </div> -->
    <div class="sv-page-content-area__body">
        <div class="sv-data-view-area">
            <div class="sv-data-view-area__main-block">
                <div class="sv-data-view-main-action-area">
                    <div class="sv-data-view-main-action-area" style="flex: 1 1 400px">
                        <button type="button" (click)="goHome()" id="act-refresh"
                            class="__data-tree-tool-toggle sv-btn -icon-only sv-bgc--gray-10 sv-bgc--gray-20--hover sv-bgc--gray-30--active sv-bgc--gray-30--activated h-easing--flash sv-icon-color--primary"
                            pTooltip="{{'Thư mục gốc' | translate }}">
                            <span class="__icon ci ci-home"></span>
                        </button>
                    </div>
                </div>

                <div class="__record-action-block" [hidden]="selectedItems && !selectedItems.length">
                    <div class="sv-data-view-main-action-area" style="flex: 1 1 400px">
                        <div class="ml-16 mb-8 mr-28 __selected-record-number">
                            {{selectedItems.length}} {{'LIST_ROWS_SELECTED' | translate}}
                        </div>
                        <button type="button" (click)="restoreItem()" class="sv-btn icon-only __record-action-btn"
                            pTooltip="{{'Khôi phục' | translate }}">
                            <span class="__icon ci ci-undo"></span>
                        </button>
                        <button type="button" (click)="deleteItem()" class="sv-btn icon-only __record-action-btn"
                            pTooltip="{{'Xóa vĩnh viễn' | translate }}">
                            <span class="__icon ci ci-delete"></span>
                        </button>
                    </div>

                    <div class="__right-action-block">
                        <button type="button" class="sv-btn icon-only __record-action-btn mr-8"
                            (click)="resetBulkSelect()">
                            <span class="__icon ci ci-close"></span>
                        </button>
                    </div>
                </div>

                <div class="sv-table-wrapper">
                    <p-table [columns]="cols" [loading]="isLoading" [scrollable]="true" [value]="dataSource"
                        class="sv-table -status-style-border-left" scrollHeight="auto">
                        <ng-template pTemplate="colgroup">
                            <colgroup>
                                <col style="width: 50px">
                                <ng-container *ngFor="let col of cols">
                                    <col [hidden]="!col.visible" [style.width]="col.width">
                                </ng-container>
                            </colgroup>
                        </ng-template>

                        <ng-template let-columns pTemplate="header">
                            <tr>
                                <th>
                                    <p-triStateCheckbox (onChange)="onChangeBulkSelection()" [(ngModel)]="isCheckAll"
                                        class="sv-checkbox -primary-theme"></p-triStateCheckbox>
                                </th>

                                <ng-container *ngFor="let col of columns">
                                    <th *ngIf="col.sort" [hidden]="!col.visible" [pSortableColumn]="col.field">
                                        <div class="h-flex-el -h-space-between">
                                            <div class="h-overflow-break-word">{{col.header}}</div>
                                            <p-sortIcon [field]="col.field"></p-sortIcon>
                                        </div>
                                    </th>

                                    <th *ngIf="!col.sort" [hidden]="!col.visible">
                                        <div class="h-overflow-break-word">{{col.header}}</div>
                                    </th>
                                </ng-container>
                            </tr>
                        </ng-template>

                        <ng-template let-columns="columns" let-rowData let-rowIndex="rowIndex" pTemplate="body">
                            <tr [class.sv-tr-status-danger]="rowData.status === 1"
                                [class.sv-tr-status-success]="rowData.status === 3"
                                [class.sv-tr-status-warning]="rowData.status === 2"
                                [class.sv-tr-status]="rowData.status">
                                <td>
                                    <p-checkbox (onChange)="checkSelectionState()" [(ngModel)]="selectedItems"
                                        [value]="rowData" class="sv-checkbox -primary-theme" name="group"></p-checkbox>
                                </td>

                                <td *ngFor="let col of columns" [hidden]="!col.visible" [ngSwitch]="col.field">
                                    <span *ngSwitchCase="'modified'">
                                        {{rowData.modified | date:'dd/MM/yyyy HH:mm'}}
                                    </span>
                                    <span *ngSwitchCase="'ten'">
                                        <span class="ci ci-document" [hidden]="rowData.idFsItemType == 1"></span>
                                        <span class="ci ci-folder" [hidden]="rowData.idFsItemType > 1"></span>
                                        {{rowData.ten}}
                                    </span>
                                    <span *ngSwitchDefault>{{rowData[col.field]}}</span>
                                </td>
                            </tr>
                        </ng-template>
                    </p-table>
                </div>
            </div>
        </div>
    </div>
</div>
