import { FsItemModel } from './../../../models/fsitem';
import { FsItemSharedService } from './../../../services/fs-item-shared.service';
import { Component, OnInit, Injector } from '@angular/core';
import { SecondPageEditBase } from '../../../classes/base/second-page-edit-base';
import { UserService } from '../../../services/user.service';

@Component({
    selector: 'app-fssystem-item-shared',
    templateUrl: './fssystem-item-shared.component.html',
    styleUrls: ['./fssystem-item-shared.component.scss']
})
export class FssystemItemSharedComponent extends SecondPageEditBase implements OnInit {
    dsUsers = [];
    arrUsers = [];
    dataSource = [];
    fsItem: FsItemModel = new FsItemModel();
    cols = [];
    constructor(

        protected _fsItemSharedService: FsItemSharedService,
        protected _userService: UserService,
        protected _injector: Injector
    ) {
        super(null, _injector);

    }

    ngOnInit() {
        this.invalid = this.formGroup.invalid;

        this.cols = [
            { field: 'userName', header: this._translateService.instant('Tài khoản'), visible: true, width: 'auto', },
            { field: 'displayName', header: this._translateService.instant('Họ & Tên'), visible: true, width: 'auto', },
            // { field: 'isEditor', header: this._translateService.instant('Chỉnh sửa?'), visible: true, width: 'auto', },
        ];
    }

    async loadUsers() {
        this.dsUsers = [];
        var model = {
            keyword: '',
            idDmTruongHoc: 0,
            trangThai: 1,
            idPhongBan: 0,
            idChucVu: 0,
            pageIndex: 1,
            pageSize: 999999,
            typeId: 1,
            orderCol: 'userName',
            isDesc: false
        }
        await this._userService.find(model).then(rs => {
            if (rs.success) {
                this.arrUsers = rs.data;
                rs.data.map(item =>
                    this.dsUsers.push({ label: item.userName + '.(' + item.displayName + ')', value: item.id })
                );
            }
        });
    }

    async onShowPopup(data: FsItemModel) {
        this.fsItem = data;
        this.isShow = true;
        if (this.dsUsers.length === 0) {
            await this.loadUsers();
        }
        this._fsItemSharedService.getsSharedByItem(data).then(rs => {
            if (rs.success) {
                this.dataSource = rs.data;
            }
        })
    }

    onChangeUser(item) {
        const perInfo = this.arrUsers.filter(x => x.id === item.userIdShared)[0];
        if (perInfo) {
            item.userName = perInfo.userName;
            item.displayName = perInfo.displayName;
        }
    }

    onAddUser() {
        const newItem = {
            isEdit: true,
            userIdShared: null,
            physicalPath: this.fsItem.physicalPath,
            idFsItemType: this.fsItem.idFsItemType,
            ten: this.fsItem.ten,
            userName: '',
            displayName: '',
            isEditor: false,
        };
        if (this.dataSource === undefined) {
            this.dataSource = [newItem];
        } else {
            this.dataSource.push(newItem);
        }
    }
    onCancelItem(item) {
        if (item) {
            item.isEdit = !item.isEdit;
        }
    }

    onEditItem(item) {
        item.isEdit = !item.isEdit;
    }

    onDeleteItem(item) {
        this._notifierService.showDeleteConfirm().then(rs => {
            const index: number = this.dataSource.indexOf(item);
            if (index !== -1) {
                this.dataSource.splice(index, 1);
                this._notifierService.showDeleteDataSuccess();
            }

        });
    }

    onSave() {
        var model = {
            physicalPath: this.fsItem.physicalPath,
            dsShared: this.dataSource,
        };
        this._fsItemSharedService.updateSharedByItem(model).then(rs => {
            if (rs.success) {
                this._notifierService.showUpdateDataSuccess();
                this.closePopupMethod(true);
            }
        })
    }

}
