import { FsSystemFileService } from './../../../services/fssystem-file.service';
import { Component, OnInit, Injector, ViewChild, ElementRef } from '@angular/core';
import { FileUpload } from 'primeng/fileupload';
import { SecondPageEditBase } from '../../../classes/base/second-page-edit-base';
import { HttpClient, HttpEventType, HttpRequest } from '@angular/common/http';


@Component({
    selector: 'app-fssytem-folder-upload',
    templateUrl: './fssytem-folder-upload.component.html',
    styleUrls: ['./fssytem-folder-upload.component.scss']
})
export class FssytemFolderUploadComponent extends SecondPageEditBase implements OnInit {

    progressValue = 0;
    apiUploadUrl = '';
    accept = 'image/*,.doc,.docx,.xls,xlsm,.xlsx,ppt,pptm,pptx,.pdf,.rar,.zip,docm,dot,dotm,dotx,epub,htm,html,mht,odt,ott,rtf,txt,djvu,xps,csv,fods,ods,ots,xlt,xltm,xltx,fodp,odp,otp,pot,potm,potx,pps,ppsm,ppsx';
    maxFileSize = 1000000000;
    physicalPath = '';
    uploadedFiles: any[] = [];
    formData = new FormData();
    @ViewChild('fileUpload') fileUpload: ElementRef;
    cols = [];
    constructor(
        protected _fsSystemFileService: FsSystemFileService,
        protected _injector: Injector,
        private http: HttpClient,
    ) {
        super(_fsSystemFileService, _injector);
    }

    ngOnInit() {
        this.apiUploadUrl = this._fsSystemFileService.getUploadFolderUrl();
        this.cols = [
            { field: 'webkitRelativePath', header: this._translateService.instant('Tên file'), visible: true, width: '80%', },
            { field: 'size', header: this._translateService.instant('Dung lượng'), visible: true, width: 'auto', },
        ];
    }

    onShowPopup(data: any) {
        this.fileUpload.nativeElement.value = '';
        this.uploadedFiles = [];
        this.formData = new FormData();
        this.physicalPath = data.physicalPath;
    }


    onChangeFolder(event) {
        this.uploadedFiles = [];
        this.formData = new FormData();
        const element = event.currentTarget as HTMLInputElement;
        let fileList: FileList | null = element.files;
        for (var i = 0; i < element.files.length; i++) {
            this.uploadedFiles.push(fileList[i]);
            this.formData.append(fileList[i].name, fileList[i]);
        }
        this.formData.append('ownedId', this.currentUser.userId.toString());
        this.formData.append('physicalPath', this.physicalPath);
    }

    uploadFolder() {

        const uploadReq = new HttpRequest('POST', this.apiUploadUrl, this.formData, {
            reportProgress: true,
        });

        this.http.request(uploadReq).subscribe(event => {
            if (event.type === HttpEventType.UploadProgress) {
                this.progressValue = Math.round(100 * event.loaded / event.total);
                this.submitting = true;
            }
            else if (event.type === HttpEventType.Response) {
                this.submitting = false;
                if (event.body['success']) {
                    this._notifierService.showSuccess('Upload thành công');
                    this.closePopupMethod(true);
                } else {
                    this._notifierService.showWarning(event.body['error']);
                }
            }
        });
    }

}
