<p-dialog #dialog [header]="'Tải file lên kho dữ liệu'" class="sv-modal" [(visible)]="isShow" [modal]="true"
    [responsive]="true" [styleClass]="'sv-modal force-full-width'" [contentStyle]="{'max-height': getMaxDialogHeight()}"
    [style]="{'width': '800px'}" closeIcon="ci ci-close" maximizeIcon="ci ci-maximize" minimizeIcon="ci ci-minimize"
    [maximizable]="true" [autoZIndex]="true">
    <div class="sv-modal-content-panel sv-form-container">
        <div class="ui-g -large-gutter -row sv-form-row">
        </div>
        <div class="ui-g -large-gutter -row sv-form-row">
            <div class="ui-g-12 ui-lg-12">
                <p-fileUpload #fileControl [multiple]="true" mode="advanced" [uploadLabel]="'Tải file'" auto="true"
                    [cancelLabel]="'Hủy tải lên'" [chooseLabel]="'Chọn nhiều file'" name="file" url="{{apiUploadUrl}}"
                    [maxFileSize]="maxFileSize" (onProgress)="onUploadProgress($event)" (onUpload)="onUpload($event)"
                    (onBeforeUpload)="onBeforeUpload($event)" (onUpload)="onUploaded($event)">
                    <ng-template pTemplate="content">
                        <ul *ngIf="uploadedFiles.length">
                            <li *ngFor="let file of uploadedFiles">{{file.name}} - {{file.size}} bytes</li>
                        </ul>
                    </ng-template>
                </p-fileUpload>
            </div>
            <div class="ui-g-12 ui-lg-12 p-col">
                <div class="progress-bar" role="progressbar" [ngStyle]="{ 'width': progressValue + '%' }"></div>
                <p-progressBar [value]="progressValue"></p-progressBar>
            </div>
        </div>
    </div>
    <p-footer>
    </p-footer>
</p-dialog>