import { Subject } from 'rxjs/internal/Subject';
import { Directive, OnDestroy } from '@angular/core';

@Directive()
export abstract class ComponentBase implements OnDestroy {

    public _unsubscribeAll: Subject<any>;

    constructor() {
        this._unsubscribeAll = new Subject();
    }

    /**
    * On destroy
    */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }
}
