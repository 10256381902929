import { Injectable, Injector } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BaseService, ResponseResult } from 'sv-shared';
import { catchError } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { resolve } from 'path';
import { DmNamhoc } from '../models/dmnamhoc';

@Injectable({
    providedIn: 'root'
})
export class DmNamHocService extends BaseService {
    constructor(http: HttpClient, injector: Injector) {
        super(http, injector, `${environment.apiDomain.svmlearningEndpoint}/dmnamhoc`);
    }

    GetActive(): Promise<ResponseResult> {
        const apiUrl = `${this.serviceUri}/GetActive`;
        return this.defaultGet(apiUrl);
    }

    async GetSchoolYear(): Promise<DmNamhoc> {
        const storageItem = localStorage.getItem("DmNamHoc.Active");
        if (storageItem) {
            const result = JSON.parse(storageItem) as DmNamhoc;
            return result
        } else {
            await this.GetActive().then(rs => {
                if (rs.success) {
                    localStorage.setItem("DmNamHoc.Active", JSON.stringify(rs.data));
                    return rs.data;
                } else {
                    return null;
                }
            });
        }
    }
}
