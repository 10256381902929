<p-dialog #dialog [header]="'Tải folder lên hệ thống'" class="sv-modal" [(visible)]="isShow" [modal]="true"
    [responsive]="true" [styleClass]="'sv-modal force-full-width'" [style]="{'width': '1300px'}" closeIcon="ci ci-close"
    maximizeIcon="ci ci-maximize" minimizeIcon="ci ci-minimize" [maximizable]="true" [autoZIndex]="true">
    <div class="sv-modal-content-panel sv-form-container">

        <div class="ui-g large-gutter row sv-form-row mb-10">
            <div class="ui-g-12 ui-lg-12">
                <div class="sv-form-item -required">
                    <div class="sv-form-item__label" for="ten">{{'Chọn folder' | translate}}</div>
                    <div class="sv-form-item__input-wrapper -type-text-field">
                        <input type="file" #fileUpload webkitdirectory multiple (change)="onChangeFolder($event)">
                    </div>
                </div>
            </div>
        </div>

        <div class="ui-g large-gutter row sv-form-row" [hidden]="uploadedFiles.length == 0">
            <div class="sv-table-wrapper">
                <p-table [columns]="cols" [value]="uploadedFiles" class="sv-table -status-style-border-left"
                    scrollHeight="auto">
                    <ng-template pTemplate="colgroup">
                        <colgroup>
                            <ng-container *ngFor="let col of cols">
                                <col [hidden]="!col.visible" [style.width]="col.width">
                            </ng-container>
                        </colgroup>
                    </ng-template>

                    <ng-template let-columns pTemplate="header">
                        <tr>
                            <ng-container *ngFor="let col of columns">
                                <th *ngIf="col.sort" [hidden]="!col.visible" [pSortableColumn]="col.field">
                                    <div class="h-flex-el -h-space-between">
                                        <div class="h-overflow-break-word">{{col.header}}</div>
                                        <p-sortIcon [field]="col.field"></p-sortIcon>
                                    </div>
                                </th>

                                <th *ngIf="!col.sort" [hidden]="!col.visible">
                                    <div class="h-overflow-break-word">{{col.header}}</div>
                                </th>
                            </ng-container>
                        </tr>
                    </ng-template>

                    <ng-template let-columns="columns" let-rowData let-rowIndex="rowIndex" pTemplate="body">
                        <tr>
                            <td *ngFor="let col of columns" [hidden]="!col.visible" [ngSwitch]="col.field">
                                <span *ngSwitchCase="'size'">{{rowData.size | fileSize}}</span>
                                <span *ngSwitchDefault>{{rowData[col.field]}}</span>
                            </td>
                        </tr>
                    </ng-template>
                </p-table>
            </div>
        </div>
        <div class="ui-g large-gutter row sv-form-row">
            <div class="ui-g-12 ui-lg-12 p-col">
                <div class="progress-bar" role="progressbar" [ngStyle]="{ 'width': progressValue + '%' }"></div>
                <p-progressBar [value]="progressValue"></p-progressBar>
            </div>
        </div>
    </div>
    <p-footer>
        <div class="ui-g -large-gutter -row sv-modal-footer-content">
            <div class="ui-g-8 __left-content">

            </div>
            <div class="ui-g-4 __right-content">
                <button type="button" (click)="uploadFolder()" *ngIf="uploadedFiles.length > 0" [disabled]="submitting"
                    class="sv-btn sv-color--white-0 sv-bgc--primary sv-bgc--primary-alt--hover sv-bgc--primary-alt-2--active h-easing--flash">
                    <span class="__label">{{'Tải lên' | translate}}</span>
                    <span class="__icon ci ci-save"></span>
                </button>
            </div>
        </div>
    </p-footer>
</p-dialog>