<p-dialog #dialog [header]="'Cập nhật thông tin thư mục'" class="sv-modal" [(visible)]="isShow" [modal]="true"
    [responsive]="true" [styleClass]="'sv-modal force-full-width'" [contentStyle]="{'max-height': getMaxDialogHeight()}"
    [style]="{'width': '700px'}" closeIcon="ci ci-close" maximizeIcon="ci ci-maximize" minimizeIcon="ci ci-minimize"
    [maximizable]="true" [autoZIndex]="true">
    <div class="sv-modal-content-panel sv-form-container">
        <form #formElement [formGroup]="formGroup">
            <div class="ui-g row">
                <validation-summary #validationSummary [formElement]="formElement" [formGroup]="formGroup">
                </validation-summary>
            </div>
            <div class="ui-g large-gutter row sv-form-row">
            </div>

            <div class="ui-g large-gutter row sv-form-row">
                <div class="ui-g-12 ui-lg-12">
                    <div class="sv-form-control type-text-field required">
                        <div class="__control-label" for="ten">{{'Tên' | translate}}</div>
                        <div class="__control-input-wrapper">
                            <input class="__control-input" type="text" [(ngModel)]="itemDetail.ten"
                                formControlName="ten" placeholder="Tên ">
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
    <p-footer>
        <div class="ui-g -large-gutter -row sv-modal-footer-content">
            <div class="ui-g-8 __left-content">
                <button type="button" (click)="delete()" *ngIf="itemDetail.id > 0" [disabled]="submitting"
                    class="sv-btn sv-bgc--transparent sv-bgc--gray-20--hover sv-bgc--gray-30--active easing-fast focus-style-1 sv-icon-color--red-60">
                    <span class="__label">{{'Xóa' | translate}}</span>
                    <span class="__icon ci ci-delete"></span>
                </button>
            </div>
            <div class="ui-g-4 __right-content">
                <button type="button" (click)="saveAndClose()" [disabled]="submitting"
                    class="sv-btn sv-color--white-0 sv-bgc--primary sv-bgc--primary-alt--hover sv-bgc--primary-alt-2--active easing-fast">
                    <span class="__label">{{'FORM_SAVE' | translate}}</span>
                    <span class="__icon ci ci-save"></span>
                </button>
            </div>
        </div>
    </p-footer>
</p-dialog>