import { NgModule } from '@angular/core';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutes } from './app.routes';
import { AppComponent } from './app.component';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import {
    MultiTranslateHttpLoader,
    SendAccessTokenInterceptor,
    SvComponentModule,
    UserFormatPipe,
    UserService,
    SvCorePublicService,
} from 'sv-shared';
import { environment } from '../environments/environment';
import { OAuthModule } from 'angular-oauth2-oidc';
import { DatePipe, CommonModule } from '@angular/common';
import { QuicklinkModule } from 'ngx-quicklink';
import { GlobalService } from './services/global.service';
import { DefaultLayoutComponent } from './layouts/default-layout/default-layout.component';
import { ToastModule } from "primeng/toast";
import { ClickOutsideModule } from "ng-click-outside";
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AuthGuardService } from './services/auth-guard.service';
import { AuthGuardLoginService } from './services/auth-guard-login.service';
import { AppMenuComponent } from './app-content/menu/app-menu.component';
import { AppFooterComponent } from './app-content/app-footer/app-footer.component';
import { LearningLayoutComponent } from './layouts/learning-layout/learning-layout.component';
import { CountdownModule } from 'ngx-countdown';
import { SocialLoginModule, SocialAuthServiceConfig } from 'angularx-social-login';
import {
    GoogleLoginProvider,
    FacebookLoginProvider,
    AmazonLoginProvider,
} from 'angularx-social-login';
import { HomeComponent } from './specific-page/home/home.component';
import { SigninComponent } from './specific-page/signin/signin.component';
import { SvCommonModule } from './sv-common/sv-common.module';
import { NgHttpLoaderModule } from 'ng-http-loader';
import { AdminRedirectComponent } from './specific-page/admin-redirect/admin-redirect.component';
import { ConfirmationService, MessageService } from 'primeng/api';
import { CarouselModule } from 'primeng/carousel';

export function createTranslateLoader(http: HttpClient) {
    return new MultiTranslateHttpLoader(http,
        [
            { prefix: './assets/i18n/', suffix: '.json' },
            { prefix: './assets/i18n/shared/', suffix: '.json' }
        ]
    );
}

@NgModule({
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        AppRoutes,
        HttpClientModule,
        ToastModule,
        FormsModule,
        ClickOutsideModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: createTranslateLoader,
                deps: [HttpClient, SvCorePublicService]
            }
        }),
        OAuthModule.forRoot({
            resourceServer: {
                allowedUrls: [environment.apiDomain.gateway],
                sendAccessToken: false
            }
        }),
        SvComponentModule.forRoot(),
        QuicklinkModule,
        ReactiveFormsModule,
        SvComponentModule,
        CountdownModule,
        SocialLoginModule,
        CarouselModule,
        SvCommonModule,
        NgHttpLoaderModule.forRoot(),
    ],
    declarations: [
        AppComponent,
        DefaultLayoutComponent,
        LearningLayoutComponent,
        AppMenuComponent,
        AppFooterComponent,
        HomeComponent,
        SigninComponent,
        AdminRedirectComponent,
    ],
    providers: [
        {
            provide: HTTP_INTERCEPTORS,
            useClass: SendAccessTokenInterceptor,
            multi: true
        },
        AuthGuardService,
        AuthGuardLoginService,
        MessageService,
        ConfirmationService, DatePipe, UserFormatPipe, UserService, GlobalService,
        {
            provide: 'SocialAuthServiceConfig',
            useValue: {
                autoLogin: false,
                providers: [
                    {
                        id: GoogleLoginProvider.PROVIDER_ID,
                        provider: new GoogleLoginProvider(environment.SocialLoginProvider.Google),
                    },
                    {
                        id: FacebookLoginProvider.PROVIDER_ID,
                        provider: new FacebookLoginProvider(environment.SocialLoginProvider.Facebook),
                    },
                ],
            } as SocialAuthServiceConfig,
        }
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
